import { useState, useEffect, useCallback, useReducer } from "react";
import Web3 from "web3";
import "../css/TagBattleWidget.css";
import "../css/basix.css";
import Navbar from "../widgets/NavBar";
import Modal from "../widgets/ModalTag.js";
import { convertBigIntToFloat, formatNumberWithCommas } from "../funcs/bigint.js";
import { getTagBattle } from "../funcs/getContract.js";
import { shortenAddress, copyToClipboard } from "../funcs/address.js";
import { initialTagBattleState, tagBattleReducer } from "../reducers/tagBattle.js";
import { useWallet } from "../contexts/WalletContext.js"; // adjust if needed
import { useBalances } from "../hooks/UseBalances.js";


const contractAddress = "0x5cA771A8cB1a51251174A9dfC2f06182d84914F6";

const TagBattleWidget = () => {
    const [state, dispatch] = useReducer(tagBattleReducer, initialTagBattleState);
    const { web3, account } = useWallet();
    const { userBalance: kiddoBalance, userShidoBalance: shidoBalance, userAllowance: allowance, approveAllowance, refreshAllowance } = useBalances(contractAddress);

    const [contract, setContract] = useState(null);
    const [newMessage, setNewMessage] = useState("");
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("kiddo");

    const [cooldownRemaining, setCooldownRemaining] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalAction, setModalAction] = useState(() => { });
    const [feeValue, setFeeValue] = useState("");
    const [activeCard, setActiveCard] = useState("message");
    const {
        currentMessage,
        currentLikes,
        cooldownDuration,
        cooldownEndTime,
        totalMessages,
        totalLikes,
        totalKiddoEarned,
        totalShidoEarned,
        senderLikes,
        kiddoLikeEarnings,
        shidoLikeEarnings,
        userLikes,
        userKiddoLikeEarnings,
        userShidoLikeEarnings,
        messageCount,
        totalKiddoDistributed,
        totalShidoDistributed,
        currentSeason,
        currentSender,
        creatorSharePercentage,
        hasLiked,
        shidoFee,
        kiddoFee,
        shidoLikeFee,
        kiddoLikeFee,
    } = state;

    const ADMIN_ADDRESS = "0x062f444E491016dd87a46009798956C910108E40";
    const isAdmin = (account || "").toLowerCase() === ADMIN_ADDRESS.toLowerCase(); // Check if logged-in account is admin
    const prizePool = 250000;


    // ✅ Fetch Global Data (No Wallet Needed)
    const fetchGlobalData = useCallback(async () => {
        try {
            if (!web3) return;
            const sc_data = await getTagBattle(web3);
            setContract(sc_data.tagBattleContract);
            //const kiddoContractInstance = sc_data.kiddoContractInstance;
            //setKiddoContract(kiddoContractInstance);

            const data = await Promise.all([
                sc_data.tagBattleContract.methods.currentMessage().call(),
                sc_data.tagBattleContract.methods.totalMessages().call(),
                sc_data.tagBattleContract.methods.totalLikes().call(),
                sc_data.tagBattleContract.methods.totalKiddoEarned().call(),
                sc_data.tagBattleContract.methods.totalShidoEarned().call(),
                sc_data.tagBattleContract.methods.creatorSharePercentage().call(),
                sc_data.tagBattleContract.methods.shidoFee().call(),
                sc_data.tagBattleContract.methods.kiddoFee().call(),
                sc_data.tagBattleContract.methods.likeShidoFee().call(),
                sc_data.tagBattleContract.methods.likeKiddoFee().call(),
                sc_data.tagBattleContract.methods.owner().call(),
                sc_data.tagBattleContract.methods.cooldownDuration().call(),
                sc_data.tagBattleContract.methods.cooldownEndTime().call(),
                sc_data.tagBattleContract.methods.currentLikes().call(),
                sc_data.tagBattleContract.methods.currentSeason().call(),
                sc_data.tagBattleContract.methods.currentSender().call(),
                sc_data.tagBattleContract.methods.totalKiddoDistributed().call(),
                sc_data.tagBattleContract.methods.totalShidoDistributed().call(),
            ]);

            dispatch({
                currentMessage: data[0],
                totalMessages: data[1],
                totalLikes: data[2],
                totalKiddoEarned: data[3],
                totalShidoEarned: data[4],
                creatorSharePercentage: data[5],
                shidoFee: web3.utils.fromWei(data[6], "ether"),
                kiddoFee: web3.utils.fromWei(data[7], "ether"),
                shidoLikeFee: web3.utils.fromWei(data[8], "ether"),
                kiddoLikeFee: web3.utils.fromWei(data[9], "ether"),
                cooldownDuration: data[11],
                cooldownEndTime: data[12],
                currentLikes: data[13],
                currentSeason: data[14],
                currentSender: data[15],
                totalKiddoDistributed: web3.utils.fromWei(data[16], "ether"),
                totalShidoDistributed: web3.utils.fromWei(data[17], "ether"),
            });


            // Fetch earnings for the current message sender
            const [kiddoLikeEarnings, shidoLikeEarnings, senderLikes, messageCount] = await Promise.all([
                sc_data.tagBattleContract.methods.kiddoLikeEarnings(data[15]).call(),
                sc_data.tagBattleContract.methods.shidoLikeEarnings(data[15]).call(),
                sc_data.tagBattleContract.methods.globalLikesReceived(data[15]).call(),
                sc_data.tagBattleContract.methods.messageCount(data[15]).call(),

            ]);

            dispatch({
                kiddoLikeEarnings: web3.utils.fromWei(kiddoLikeEarnings, "ether"),
                shidoLikeEarnings: web3.utils.fromWei(shidoLikeEarnings, "ether"),
                senderLikes,
                messageCount
            });

        } catch (error) {
            console.error("Error fetching global contract data:", error);
        }
    }, [web3]); // ✅ Dependencies to avoid re-creation

    // ✅ Fetch User-Specific Data (Only After Login)
    const fetchUserData = useCallback(async (account) => {
        if (!account) {
            console.warn("No account connected — skipping fetchUserData");
            return;
        }
        if (!contract) {
            console.warn("Contract not initialized — skipping fetchUserData");
            return;
        }
        try {
            const [
                userLikesReceived,
                userKiddoLikeEarnings,
                userShidoLikeEarnings,
                hasLiked
            ] = await Promise.all([
                contract.methods.globalLikesReceived(account).call(),
                contract.methods.kiddoLikeEarnings(account).call(),
                contract.methods.shidoLikeEarnings(account).call(),
                contract.methods.hasLiked(account).call(),
            ]);

            dispatch({
                userLikes: userLikesReceived,
                userKiddoLikeEarnings: web3.utils.fromWei(userKiddoLikeEarnings, "ether"),
                userShidoLikeEarnings: web3.utils.fromWei(userShidoLikeEarnings, "ether"),
                hasLiked: hasLiked
            });

        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }, [contract, web3]); // ✅ Dependencies: contract ensures updates when it changes


    useEffect(() => {
        fetchGlobalData();
    }, [fetchGlobalData]); // ✅ Uses memoized function

    useEffect(() => {
        if (account && contract) {
            console.log("Contract is ready, fetching user data for:", account);
            fetchUserData(account);
        }
    }, [account, contract, fetchUserData]);





    const refresh = async () => {
        try {
            await fetchGlobalData();
            await fetchUserData();

        } catch (error) {
            console.error("Error refreshing:", error);
        }

    }

    const refreshAll = async () => {
        await refresh();
        await refreshAllowance(contractAddress);
    };

    const openModal = (title, action) => {
        console.log("Open Modal");
        setModalTitle(title);
        setModalAction(() => action);
        setIsModalOpen(true);
    };


    const handleSubmitMessage = async () => {
        if (!newMessage) return alert("Please enter a message.");
        try {
            if (selectedPaymentMethod === "shido") {
                await contract.methods
                    .submitMessageWithShido(newMessage)
                    .send(
                        { from: account, value: web3.utils.toWei(shidoFee, "ether"), })
                    .on("receipt", async (receipt) => {
                        refreshAll();
                        alert("Message submitted with SHIDO!");
                    });
            } else if (selectedPaymentMethod === "kiddo") {
                if (parseFloat(allowance) < parseFloat(kiddoFee)) {
                    return alert("Please approve KIDDO tokens before submitting the message.");
                }

                await contract.methods.submitMessageWithKiddo(newMessage).send({ from: account })
                    .on("receipt", async (receipt) => {
                        refreshAll();
                        alert("Message submitted with KIDDO!");
                    });
            }

            const updatedMessage = await contract.methods.currentMessage().call();
            dispatch({ currentMessage: updatedMessage });

        } catch (error) {
            console.error("Message submission error:", error);
            alert("Failed to submit the message.");
        }
        setIsModalOpen(false);
    };

    const handleLikeMessage = async () => {
        try {
            console.log(account);

            if (!account) {
                alert("Wallet not connected.");
                return;
            }

            if (selectedPaymentMethod === "shido") {
                /*const gasEstimate = await contract.methods
                    .likeMessageWithShido()
                    .estimateGas({
                        from: account,
                        value: web3.utils.toWei(shidoLikeFee, "ether"),
                    });

                console.log(gasEstimate, "Gas Estimate");
                */
                await contract.methods
                    .likeMessageWithShido()
                    .send({
                        from: account,
                        value: web3.utils.toWei(shidoLikeFee, "ether"),
                        //gas: gasEstimate,
                    })
                    .on("receipt", async () => {
                        refreshAll();
                        alert("Message liked with SHIDO!");
                    });

            } else if (selectedPaymentMethod === "kiddo") {
                /*console.log("KIDDO like");
                const gasEstimate = await contract.methods
                    .likeMessageWithKiddo()
                    .estimateGas({ from: account });
                console.log(gasEstimate, "Gas Estimate");
                */
                await contract.methods
                    .likeMessageWithKiddo()
                    .send({
                        from: account,
                        //gas: gasEstimate,
                    })
                    .on("receipt", async () => {
                        refreshAll();
                        alert("Message liked with KIDDO!");
                    });

            } else {
                alert("Please select a payment method.");
            }
        } catch (error) {
            console.error("Error liking the message:", error);
            alert("Failed to like the message.");
        }
        setIsModalOpen(false);
    };


    const handleSetLikeKiddoFee = async () => {
        if (!contract || !feeValue) return;
        try {
            await contract.methods.setLikeKiddoFee(Web3.utils.toWei(feeValue, "ether")).send({ from: account });
            alert("Like Kiddo Fee updated successfully!");
        } catch (error) {
            console.error("Error updating Like Kiddo Fee:", error);
            alert("Failed to update Like Kiddo Fee.");
        }
    };

    const handleSetKiddoFee = async () => {
        if (!contract || !feeValue) return;
        try {
            await contract.methods.setKiddoFee(Web3.utils.toWei(feeValue, "ether")).send({ from: account });
            alert("Kiddo Fee updated successfully!");
        } catch (error) {
            console.error("Error updating Kiddo Fee:", error);
            alert("Failed to update Kiddo Fee.");
        }
    };

    const renderSubmitMessage = () => (
        <div className="box submit-message">
            <h3>Submit a New Message</h3>
            {renderDetails()}
            <input
                type="text"
                placeholder="Write your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
            />
            {cooldownRemaining > 0 && (
                <p style={{ color: "red" }}>
                    Cooldown active: {cooldownRemaining} sec
                </p>
            )}
            <button
                onClick={() => openModal("Submit Message", handleSubmitMessage)}
                disabled={cooldownRemaining > 0}
            >
                Submit Message
            </button>
        </div>
    );

    const renderDetails = () => (
        <div className="glass pad15">
            {/* Title */}
            <h3 className="details-title">🚀 Message Benefits</h3>

            {/* Sections */}
            <div className="details-grid">
                {/* Message Visibility */}
                <div className="glass details-item pad10">
                    <p><span className="icon">🔥</span> <strong>Visibility:</strong></p>
                    <p>Your message is broadcasted on <span className="highlight">SHIDO Chain</span> and <span className="highlight">shared in our Telegram!</span></p>
                </div>

                {/* Engagement Prizes */}
                <div className="glass details-item pad10">
                    <p><span className="icon">🏆</span> <strong>Engagement Prizes:</strong></p>
                    <p>Earn <span className="highlight">{creatorSharePercentage.toString()}% of the fees</span> from likes + <span className="highlight">Seasonal Rewards!</span></p>
                </div>

                {/* Use KIDDO */}
                <div className="glass details-item pad10">
                    <p><span className="icon">💎</span> <strong>Use KIDDO:</strong></p>
                    <p>Gain <span className="highlight">increased cooldown protection</span> for more efficient posting.</p>
                </div>
            </div>

            {/* Cooldown Section */}
            <div className="cooldown-section pad10">
                <h4>⏳ Cooldown Duration</h4>
                <div className="cooldown-grid">
                    <p><span className="cooldown-icon">🟦</span> <strong>SHIDO Likes:</strong> <span className="highlight">{cooldownDuration.toString()}</span></p>
                    <p><span className="cooldown-icon">💠</span> <strong>KIDDO Likes/Messages:</strong> <span className="highlight">{cooldownDuration.toString() * 2}</span></p>
                </div>
            </div>
        </div>
    );

    const renderFees = () => (
        <div className="fees-container">
            <h3 className="section-title">🏷️ Fees</h3>
            <div className="fees-grid flexCenter">
                <div className="glass fee-card pad10">
                    <p><strong>Message Fee</strong></p>
                    <p>💰 SHIDO: {formatNumberWithCommas(shidoFee)}</p>
                    <p>💰 KIDDO: {formatNumberWithCommas(kiddoFee)}</p>
                </div>
                <div className="glass fee-card pad10">
                    <p><strong>Like Fee</strong></p>
                    <p>💖 SHIDO: {formatNumberWithCommas(shidoLikeFee)}</p>
                    <p>💖 KIDDO: {formatNumberWithCommas(kiddoLikeFee)}</p>
                </div>
            </div>
        </div>
    );

    const renderBalances = () => (
        <div className="box balances">
            <h3 className="balances-title"><strong>Balances</strong></h3>
            <div className="balances-table flexCenter">
                <div className="balances-row">
                    <div className="glass balances-card pad10">
                        <p>🔥 SHIDO</p>
                        <p>{formatNumberWithCommas(parseFloat(shidoBalance).toFixed(4))}</p>
                    </div>
                    <div className="glass balances-card pad10">
                        <p>💎 KIDDO</p>
                        <p>{formatNumberWithCommas(parseFloat(kiddoBalance).toFixed(4))}</p>
                    </div>
                </div>
            </div>
            {renderFees()}
        </div>
    );


    const renderMessageBoxWithLike = () => (
        <div className="flexColumnCenter">
            {/* Message Card */}
            <div className="box message-box pad15">
                <p className="highlight message-content">
                    <strong>{currentMessage || "No messages yet. Be the first!"}</strong>
                </p>

                {/* Sender Info */}
                <p className="sender-info" title={currentSender}>
                    By:
                    <b className="clickable-address" onClick={() => copyToClipboard(currentSender)}>
                        {shortenAddress(currentSender)}
                    </b>
                </p>

                {/* Stats Section */}
                <div className="message-stats flexColumnCenter">
                    {/* Row 1: Likes & Messages Sent */}
                    <div className="stats-row pad10 w100">
                        <p className="glass">❤️ Likes: <strong>{currentLikes.toString()}</strong></p>
                        <p className="glass">📩 Messages Sent: <strong>{messageCount.toString()}</strong></p>
                        <p className="glass">🏆 Total Likes: <strong>{senderLikes.toString()}</strong></p>
                    </div>

                    {/* Row 2: Total Likes Received & Earnings */}
                    <div className="earnings-box pad10">

                        <p className="glass">💰 Earnings: <strong>{formatNumberWithCommas(kiddoLikeEarnings)} KIDDO & {formatNumberWithCommas(shidoLikeEarnings)} SHIDO</strong></p>
                    </div>
                </div>


                {/* Liked Confirmation */}
                {currentMessage && hasLiked && (
                    <div className="liked-confirmation">
                        <p>✅ You liked this message!</p>
                    </div>
                )}
                {currentMessage && !hasLiked && (
                    <div className="like-button-container">
                        <button className="like-button" onClick={() => openModal("Like Message", handleLikeMessage)}>
                            ❤️ Like Message
                        </button>
                    </div>
                )}
            </div>


        </div>
    );


    const renderStats = () => (
        <div className="box stats-container">
            <h3 className="myh3 stats-title">📊 Tag Battle Stats</h3>
            <div className="stats-grid pad20">
                <div className="glass stat-card pad15">🔥 Current Season: <strong>{currentSeason.toString()}</strong></div>
                <div className="glass stat-card pad15">📝 Total Messages: <strong>{totalMessages.toString()}</strong></div>
                <div className="glass stat-card pad15">❤️ Total Likes: <strong>{totalLikes.toString()}</strong></div>

            </div>
            <h3 className="myh3 stats-title">🏦 Funds Raised</h3>
            <div className="stats-grid">
                <div className="glass stat-card pad15">🏆 Kiddo: <strong>{formatNumberWithCommas(convertBigIntToFloat(totalKiddoEarned).toFixed(0))}</strong></div>
                <div className="glass stat-card pad15">💰 Shido: <strong>{formatNumberWithCommas(convertBigIntToFloat(totalShidoEarned))}</strong></div>

            </div>

            <h3 className="myh3 stats-title">💝 Funds Distributed</h3>
            <div className="stats-grid">
                <div className="glass stat-card pad15">🎁 Kiddo Distributed: <strong>{formatNumberWithCommas(totalKiddoDistributed)}</strong></div>
                <div className="glass stat-card pad15">🎁 Shido Distributed: <strong>{formatNumberWithCommas(totalShidoDistributed)}</strong></div>

            </div>
            <h3 className="myh3 stats-title">💎 Your Stats</h3>
            <div className="stats-grid">
                <div className="glass stat-card pad15">👍 Likes Received: <strong>{userLikes.toString()}</strong></div>
                <div className="glass stat-card pad15">💰 KIDDO Earned: <strong>{formatNumberWithCommas(userKiddoLikeEarnings)}</strong></div>
                <div className="glass stat-card pad15">💰 SHIDO Earned: <strong>{formatNumberWithCommas(userShidoLikeEarnings)}</strong></div>
            </div>
        </div>
    );

    const calcPrizePercent = (percentage) => {
        const totalPrizeKIDDO = prizePool + convertBigIntToFloat(totalKiddoEarned) / 2;
        console.log(totalPrizeKIDDO);
        return ((totalPrizeKIDDO * percentage) / 100);
    };

    const PrizeCard = ({ label, value }) => (
        <div className="glass stat-card pad15">
            {label && <strong>{label}: </strong>}
            {value}
        </div>
    );


    const renderPrizes = () => {
        const prizeDistribution = [
            { label: "Top 3 Message Senders", percentage: 40 },
            { label: "Top 3 Like Givers", percentage: 30 },
            { label: "Top 3 Like Receivers", percentage: 30 },
        ];

        const prizeTiers = [
            { place: "1st Place", factor: 0.5 },
            { place: "2nd Place", factor: 0.3 },
            { place: "3rd Place", factor: 0.2 },
        ];

        return (
            <div className="box">
                {/* Prize Pool Header */}
                <h3 className="myh3">Prize Pool - Season 0</h3>
                <div className="stats-grid">

                    <PrizeCard label="KIDDO" value={formatNumberWithCommas(prizePool)} />
                    <PrizeCard label="➕ 50% of Tag Battle KIDDO earnings" />
                    <PrizeCard label="" value={formatNumberWithCommas((convertBigIntToFloat(totalKiddoEarned) / 2).toFixed(0))} />

                </div>
                <div className="stats-grid atypical">

                    <PrizeCard label="Total" value={formatNumberWithCommas(((convertBigIntToFloat(totalKiddoEarned) / 2) + prizePool).toFixed(0))} />

                </div>

                {/* Prize Categories */}
                <div >
                    <h3 className="myh3">Prize Tiers</h3>

                    {/* 📌 Column Headers - More Visibility */}
                    <div className="stats-grid prize-header">
                        {prizeDistribution.map((prize, index) => (
                            <div key={index} className="prize-header-box">
                                <strong>{prize.label}</strong>
                            </div>
                        ))}
                    </div>

                    {/* 🏆 Prize Tiers - More Structured */}
                    {prizeTiers.map((tier, tierIndex) => (
                        <div key={tierIndex} className="prize-tier-box">
                            <h4 className="prize-tier-title">{tier.place}</h4>
                            <div className="stats-grid prize-tier-values">
                                {prizeDistribution.map((prize, index) => (
                                    <div key={`${tierIndex}-${index}`} className="prize-card">
                                        {formatNumberWithCommas(calcPrizePercent(prize.percentage) * tier.factor)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}



                </div>


            </div>
        );
    };







    useEffect(() => {
        const interval = setInterval(() => {
            const now = Math.floor(Date.now() / 1000); // Current UNIX time in seconds
            const diff = convertBigIntToFloat(cooldownEndTime, 0) - now;
            setCooldownRemaining(diff > 0 ? diff : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [cooldownEndTime]);


    return (
        <div className="tag-battle-widget text-center pad20 fvh w80">
            <Navbar />
            <h1 className="text-3xl font-extrabold my-4 mt5">Tag Battle</h1>

            <div className="box navigation-bar pad10">
                <button
                    className={activeCard === "stats" ? "active" : ""}
                    onClick={() => setActiveCard("stats")}>📊 Stats</button>


                <button
                    className={activeCard === "balances" ? "active" : ""}
                    onClick={() => setActiveCard("balances")}>💰 Balances and Fees</button>
                <button
                    className={activeCard === "message" ? "active" : ""}
                    onClick={() => setActiveCard("message")}
                >
                    📝 Current Message
                </button>
                <button
                    className={activeCard === "send" ? "active" : ""}
                    onClick={() => setActiveCard("send")}>✍️ Send Message</button>
                <button
                    className={activeCard === "prizes" ? "active" : ""}
                    onClick={() => setActiveCard("prizes")}>🏆 Season Prizes</button>
            </div>

            <div className="content-container">
                {activeCard === "message" && renderMessageBoxWithLike()}
                {activeCard === "stats" && renderStats()}
                {activeCard === "balances" && renderBalances()}
                {activeCard === "send" && renderSubmitMessage()}
                {activeCard === "prizes" && renderPrizes()}
            </div>




            {isAdmin && (
                <div className="admin-panel">
                    <h3>Admin Panel</h3>
                    <input
                        type="number"
                        placeholder="Enter fee value"
                        value={feeValue}
                        onChange={(e) => setFeeValue(e.target.value)}
                    />
                    <button onClick={handleSetLikeKiddoFee}>Set Like Kiddo Fee</button>
                    <button onClick={handleSetKiddoFee}>Set Kiddo Fee</button>
                </div>
            )}
            {/* Modal */}
            {isModalOpen && (
                <Modal
                    title={modalTitle}
                    onClose={() => setIsModalOpen(false)}
                    onAction={modalAction}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    requiresApproval={
                        selectedPaymentMethod === "kiddo" &&
                        parseFloat(allowance) < parseFloat(kiddoFee)
                    }
                    onApprove={async () => {
                        await approveAllowance(contractAddress, Web3.utils.toWei(kiddoFee, "ether"));
                        await refreshAllowance(contractAddress); // 🎯 refresh after approval
                    }}

                />
            )}
        </div>
    );



};

export default TagBattleWidget;
