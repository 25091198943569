import React from "react";
import "./myCheckbox.css"
const TextWithImage = ({ imgSrc, text, imgSize = 16, paddingLeft = 30, paddingRight = 0 }) => {
    return (
        <div
            className="text-with-image"
            style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                textAlign: "center",
                paddingLeft: `${paddingLeft}%`,
                paddingRight: `${paddingRight}%`,
                lineHeight: "2rem",
            }}>
            <img
                src={imgSrc}
                alt="icon"
                style={{ width: `${imgSize}px`, height: `${imgSize}px` }}
            />
            <p style={{ whiteSpace: "pre-line" }}>{text}</p>
        </div>
    );
};

export default TextWithImage;
