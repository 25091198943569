import React, { useState, useEffect } from "react";
import "../css/tokenFactory.css";
import "../css/basix.css";
import { connectWallet } from "../widgets/walletConnect";
import { TokenFactoryABI, TOKEN_ABI } from "../config.js";
import { useNavigate } from "react-router-dom";
import { shortenAddress, copyToClipboard } from "../funcs/address.js";
import { useWallet } from "../contexts/WalletContext.js";
import FactoryTemplate from "../widgets/FactoryTemplate";
import { useBalances } from "../hooks/UseBalances.js";

const TOKEN_FACTORY_ADDRESS = "0xF60eBeF686B75Aa397BD46c2bad1D98a49D74990";
const MIN_SHIDO_FOR_GAS = 500_000000000000000000n;

export default function TokenFactoryUI() {
    const { web3, account } = useWallet();
    const [factoryContract, setFactoryContract] = useState(null);
    const [tokenContract, setTokenContract] = useState(null);
    const [implementations, setImplementations] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [formStep, setFormStep] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [symbol, setSymbol] = useState("");
    const [supply, setSupply] = useState("");
    const [decimals, setDecimals] = useState(18);
    const [voucher, setVoucher] = useState("");
    const [factoryData, setFactoryData] = useState({ tokenTypeCount: "0", tokenDeploymentCount: "0", totalNativeEarned: "0", totalERC20Earned: "0", owner: "" });
    const [userData, setUserData] = useState({ userDeployCount: "0", isUserTracked: false });
    const [paymentMethod, setPaymentMethod] = useState("erc20");
    const [walletConnected, setWalletConnected] = useState(false);
    const [userTokens, setUserTokens] = useState([]);
    const { userBalance, userShidoBalance, userAllowance, approveAllowance } = useBalances(TOKEN_FACTORY_ADDRESS);

    const navigate = useNavigate();

    useEffect(() => {
        async function initializeWallet() {
            const walletData = await connectWallet();
            if (!walletData?.web3 || !walletData?.account) return;

            setWalletConnected(true);

            const factoryInstance = new walletData.web3.eth.Contract(TokenFactoryABI, TOKEN_FACTORY_ADDRESS);
            setFactoryContract(factoryInstance);

            const tokenInstance = new walletData.web3.eth.Contract(TOKEN_ABI, "0x2835Ad9a421C14E1C571a5Bb492B86b7E8f5873A");
            setTokenContract(tokenInstance);
        }

        initializeWallet();
    }, []);

    useEffect(() => {
        if (!walletConnected || !web3 || !account || !tokenContract) return;

        async function fetchUserData() {
            try {
                //const balance = await tokenContract.methods.balanceOf(account).call();
                //const allowance = await tokenContract.methods.allowance(account, TOKEN_FACTORY_ADDRESS).call();
                //const shidoBalance = await web3.eth.getBalance(account);
                const deployedByUser = await factoryContract.methods.userDeployCount(account).call();
                const tracked = await factoryContract.methods.isUserTracked(account).call();

                setUserData({ userDeployCount: deployedByUser.toString(), isUserTracked: tracked });

            } catch (error) {
                console.error("Error fetching user balances:", error);
            }
        }

        fetchUserData();
    }, [walletConnected, web3, account, tokenContract, factoryContract]);

    useEffect(() => {
        async function fetchUserTokens() {
            if (!factoryContract || !account || userData.userDeployCount === "0") return;

            try {
                const tokens = [];
                for (let i = 0; i < Number(userData.userDeployCount); i++) {
                    const token = await factoryContract.methods.userTokens(account, i).call();
                    tokens.push({ address: token.tokenAddress, name: token.name, symbol: token.symbol });
                }
                setUserTokens(tokens);
            } catch (error) {
                console.error("Error fetching user tokens:", error);
            }
        }

        fetchUserTokens();
    }, [factoryContract, account, userData.userDeployCount]);

    useEffect(() => {
        if (!walletConnected || !factoryContract || !web3) return;

        async function fetchFactoryData() {
            try {
                const tokenTypeCount = await factoryContract.methods.tokenTypeCount().call();
                const totalNativeEarned = await factoryContract.methods.totalNativeEarned().call();
                const totalERC20Earned = await factoryContract.methods.totalERC20Earned().call();
                const owner = await factoryContract.methods.owner().call();
                const tokenDeploymentCount = await factoryContract.methods.tokenDeploymentCount(1).call();
                const implementationsData = [];

                for (let i = 1; i <= tokenTypeCount; i++) {
                    try {
                        const impl = await factoryContract.methods.tokenImplementations(i).call();
                        if (impl) implementationsData.push({ id: i, ...impl });
                    } catch (error) {
                        console.warn(`Error fetching implementation ${i}:`, error);
                    }
                }

                setFactoryData({
                    tokenTypeCount: tokenTypeCount.toString(),
                    tokenDeploymentCount: tokenDeploymentCount.toString(),
                    totalNativeEarned: web3.utils.fromWei(totalNativeEarned, "ether"),
                    totalERC20Earned: web3.utils.fromWei(totalERC20Earned, "ether"),
                    owner,
                });

                setImplementations(implementationsData);
            } catch (error) {
                console.error("Error fetching factory data:", error);
            }
        }

        fetchFactoryData();
    }, [walletConnected, factoryContract, web3]);

    const handleDeployContract = async () => {
        if (!factoryContract || !account || !selectedType || !name.trim() || !symbol.trim() || !supply) {
            alert("Please fill in all fields and select a token type.");
            return;
        }

        try {
            const selectedImpl = implementations.find(impl => impl.id === selectedType);
            const tokenTypeId = selectedImpl.id.toString();

            let tx;
            if (paymentMethod === "shido") {
                tx = await factoryContract.methods.createTokenWithNative(
                    tokenTypeId, name, symbol, supply, decimals, voucher
                ).send({ from: account, value: selectedImpl.nativeFee, gas: 2000000 });
            } else {
                tx = await factoryContract.methods.createTokenWithERC20(
                    tokenTypeId, name, symbol, supply, decimals, voucher
                ).send({ from: account, gas: 3000000 });
            }

            alert(`Token deployed successfully!\n\nView on ShidoScan: https://shidoscan.net/tx/${tx.transactionHash}`);

            setModalOpen(false);
            setName(""); setSymbol(""); setSupply(""); setVoucher(""); setSelectedType(null); setFormStep(1);
            setTimeout(() => window.location.reload(), 1000);
        } catch (error) {
            console.error("Deployment error:", error);
            if (error?.message?.includes("User denied")) {
                alert("Transaction rejected.");
            } else if (error?.data?.message) {
                alert(`Deployment failed: ${error.data.message}`);
            } else {
                alert("Deployment failed. Check your balance and try again.");
            }
        }
    };



    return (
        <FactoryTemplate
            title="Token Factory"
            description="Your ERC20 token in the SHIDO network. Easy and secure, at the tip of some clicks."
            stats={[
                { label: "Tokens Released with the Factory", value: factoryData.tokenDeploymentCount },
                { label: "Your Deploy Count", value: userData.userDeployCount },
            ]}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            formStep={formStep}
            setFormStep={setFormStep}
            renderModalStep1={() => (
                <>
                    <h2>Choose Token Implementation</h2>
                    {implementations.map((impl) => (
                        <div key={impl.id} className="divcolor token-card">
                            <p><strong>Type {impl.id}:</strong> Basic ERC20 with ownership & burn.</p>
                            <p><strong>Native Fee:</strong> {web3.utils.fromWei(impl.nativeFee, "ether")} SHIDO</p>
                            <p><strong>ERC20 Fee:</strong> {web3.utils.fromWei(impl.erc20Fee, "ether")} KIDDO</p>
                            <button onClick={() => { setSelectedType(impl.id); setFormStep(2); }}>Select</button>
                        </div>
                    ))}
                </>
            )}
            renderModalStep2={() => (
                <>
                    <h2>Token Details</h2>
                    <input type="text" placeholder="Token Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <input type="text" placeholder="Token Symbol" value={symbol} onChange={(e) => setSymbol(e.target.value)} />
                    <input type="number" placeholder="Total Supply" value={supply} onChange={(e) => setSupply(e.target.value)} />
                    <input type="number" placeholder="Decimals" value={decimals} onChange={(e) => setDecimals(e.target.value)} />
                    <input type="text" placeholder="Voucher Code (Optional)" value={voucher} onChange={(e) => setVoucher(e.target.value)} />
                    <div className="hori">
                        <h3>Payment Method</h3>
                        <select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} className="payment-selector">
                            <option value="erc20">Pay with KIDDO</option>
                            <option value="shido">Pay with SHIDO</option>
                        </select>
                    </div>
                    <h4>Your Balances</h4>
                    <p>{walletConnected ? `${userBalance} KIDDO --- ${userShidoBalance} SHIDO` : "Loading..."}</p>
                    {selectedType && paymentMethod === "erc20" && parseFloat(userAllowance[TOKEN_FACTORY_ADDRESS]) < parseFloat(implementations.find(impl => impl.id === selectedType)?.erc20Fee) ? (
                        <button onClick={approveAllowance}>Approve {implementations.find(impl => impl.id === selectedType)?.erc20Fee} KIDDO</button>
                    ) : (
                        <button
                            disabled={
                                !name || !symbol || !supply ||
                                (paymentMethod === "shido" &&
                                    parseFloat(userShidoBalance) <
                                    (parseFloat(web3.utils.fromWei(implementations.find(impl => impl.id === selectedType)?.nativeFee || "0", "ether")) +
                                        parseFloat(web3.utils.fromWei(MIN_SHIDO_FOR_GAS.toString(), "ether"))))
                            }
                            onClick={handleDeployContract}
                        >
                            Deploy Token
                        </button>
                    )}
                </>
            )}
            renderTokens={() => (
                userTokens.length > 0 && (
                    <div className="user-tokens-container w80 tabs">
                        <h2>Your Deployed Tokens</h2>
                        <div className="token-cards">
                            {userTokens.map(token => (
                                <div key={token.address} className="divcolor token-card w80" onClick={() => navigate(`/token/${token.address}`)}>
                                    <h3>{token.name} - ${token.symbol}</h3>
                                    <b className="clickable-address" onClick={() => copyToClipboard(token.address)}>
                                        {shortenAddress(token.address)}
                                    </b>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            )}
            renderAdminPanel={() => (
                account && account.toLowerCase() === factoryData.owner.toLowerCase() && (
                    <div className="admin-panel" style={{ marginTop: "100%" }}>
                        <h2>Admin Panel</h2>
                        <p>As the contract owner, you have additional controls.</p>
                        <button className="admin-button">Manage Implementations</button>
                        <button className="admin-button">Withdraw Fees</button>
                    </div>
                )
            )}
        />
    );
}
