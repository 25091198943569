import React, { useEffect, useState } from "react";
import Navbar from "../widgets/NavBar";
import { BATCH_ABI } from "./abi.js";
import { useWallet } from "../contexts/WalletContext.js";
import { useBalances } from "../hooks/UseBalances.js";
import BatchTransferTemplate from "../widgets/BatchTransferTemplate";
import BigNumber from "bignumber.js";
const CONTRACT_ADDRESS = "0x1AD0D74967d8c91d88D88aA229a5DAf3e46538B6";

function BatchShido() {
    const { web3, account } = useWallet();
    const [contract, setContract] = useState(null);
    const { userShidoBalance: balance, refreshBalance } = useBalances(CONTRACT_ADDRESS);

    useEffect(() => {
        const fetchData = async () => {
            if (!account || !web3) return;
            const instance = new web3.eth.Contract(BATCH_ABI, CONTRACT_ADDRESS);
            setContract(instance);
        };
        fetchData();
    }, [account, web3]);

    const handleSend = async (recipients, totalAmount) => {
        const addresses = recipients.map((r) => r.address);
        const amounts = recipients.map((r) => r.amount.toString());

        const tx = await contract.methods
            .batchTransferNative(addresses, amounts)
            .send({ from: account, value: totalAmount.toString() });

        await refreshBalance();
        return tx.transactionHash;
    };

    return (
        <div className="batch-shido">
            <Navbar />
            <BatchTransferTemplate
                title="📤 Batch SHIDO Transfers"
                symbol="SHIDO"
                decimals={18}
                balance={new BigNumber(balance)}
                allowance={new BigNumber(0)} // native coins don't use allowance
                isNative={true}
                contractReady={!!contract}
                refreshBalance={refreshBalance}
                onSend={handleSend}
            />

        </div>
    );
}

export default BatchShido;
