
// Initial state for all your Tag Battle widget vars
export const initialTagBattleState = {
    currentMessage: "Loading...",
    totalMessages: "...",
    totalLikes: "...",
    totalKiddoEarned: "...",
    totalShidoEarned: "...",
    creatorSharePercentage: "50",
    shidoFee: "...",
    kiddoFee: "...",
    shidoLikeFee: "...",
    kiddoLikeFee: "...",
    isOwner: false,
    cooldownDuration: "0",
    cooldownEndTime: "0",
    cooldownRemaining: 0,
    currentLikes: "...",
    currentSeason: "0",
    currentSender: "...",
    hasLiked: false,
    totalKiddoDistributed: "0",
    totalShidoDistributed: "0",
    senderLikes: 0,
    kiddoLikeEarnings: 0,
    shidoLikeEarnings: 0,
    userLikes: 0,
    userKiddoLikeEarnings: 0,
    userShidoLikeEarnings: 0,
    messageCount: 0,
    kiddoBalance: "?",
    shidoBalance: "?",
    allowance: "0"
};

// Reducer that lets you update multiple fields at once
export const tagBattleReducer = (state, action) => {
    if (typeof action === "function") {
        return action(state);
    }
    return {
        ...state,
        ...action // allow partial updates like dispatch({ currentMessage: "New one" })
    };
};
