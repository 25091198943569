import React, { useState, useEffect } from "react";
import "../css/tokenFactory.css";
import Navbar from "../widgets/NavBar";
import { connectWallet } from "../widgets/walletConnect";
import { TokenFactoryABI } from "../config.js";
import { fetchFactoryData, fetchAllDeployedTokens } from "../funcs/factory";
import TokenCard from "../widgets/TokenCard";
import { useNavigate } from "react-router-dom"; // Navigation
import "../css/tokensPage.css";
const TOKEN_FACTORY_ADDRESS = "0xF60eBeF686B75Aa397BD46c2bad1D98a49D74990";

// 🚀 List of externally created tokens (you can expand this dynamically later)
const EXTERNAL_TOKENS = [
    //{ name: "WSHIDO", symbol: "WSHIDO", address: "0x123456789abcdef123456789abcdef1234567890" },
    { name: "KIDDO", symbol: "KIDDO", address: "0x2835Ad9a421C14E1C571a5Bb492B86b7E8f5873A" },
    //{ name: "KENSEI", symbol: "KENSEI", address: "0xaabbccddeeffaabbccddeeffaabbccddeeffaabb" }
];

export default function AllTokensPage() {
    const [factoryData, setFactoryData] = useState({});
    const [allTokens, setAllTokens] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function initializeWallet() {
            const walletData = await connectWallet();
            if (!walletData || !walletData.web3 || !walletData.account) return;

            const factoryInstance = new walletData.web3.eth.Contract(TokenFactoryABI, TOKEN_FACTORY_ADDRESS);
            const fetchedFactoryData = await fetchFactoryData(factoryInstance, walletData.web3);
            setFactoryData(fetchedFactoryData);

            if (fetchedFactoryData?.totalDeployedTokens > 0) {
                const tokens = await fetchAllDeployedTokens(factoryInstance, fetchedFactoryData.totalDeployedTokens);
                setAllTokens(tokens);
            }
        }

        initializeWallet();
    }, []);

    const handleTokenSelect = (event) => {
        const selectedAddress = event.target.value;
        if (selectedAddress) {
            navigate(`/token/${selectedAddress}`);
        }
    };

    return (
        <div className="tokens">
            <Navbar />
            <h1 id="all-tokens">Deployed Tokens</h1>
            <h2 className="divcolor w40"><strong>Total:</strong> {factoryData.totalDeployedTokens || "Loading..."}</h2>

            {/* 🔥 Token Selector Dropdown */}
            <div className="token-selector">
                <label htmlFor="external-tokens">Explore External Tokens:</label>
                <select id="external-tokens" onChange={handleTokenSelect}>
                    <option value="">-- Select a Token --</option>
                    {EXTERNAL_TOKENS.map((token) => (
                        <option key={token.address} value={token.address}>
                            {token.name} ({token.symbol})
                        </option>
                    ))}
                </select>
            </div>

            <div className="token-cards">
                {allTokens.length > 0 ? (
                    allTokens.map((token) => <TokenCard key={token.address} {...token} />)
                ) : (
                    <p>No tokens deployed yet.</p>
                )}
            </div>
        </div>
    );
}
