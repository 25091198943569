// 📌 src/components/TokenCard.js
import React from "react";
import { useNavigate } from "react-router-dom";

import { shortenAddress, copyToClipboard } from "../funcs/address.js";
import "../css/basix.css";
export default function TokenCard({ address, name, symbol }) {
    const navigate = useNavigate();

    return (
        <div className="divcolor">
            <h3>{name} ({symbol})</h3>
            <b className="clickable-address" onClick={() => copyToClipboard(address)}>
                {shortenAddress(address)}
            </b>
            <button onClick={() => navigate(`/token/${address}`)}>View Token</button>
        </div>
    );
}
