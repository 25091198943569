import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connectWallet } from "../widgets/walletConnect";
import { getTokenSC } from "../funcs/getContract";
import Navbar from "../widgets/NavBar";
import "../css/TokenDetails.css";
import { getWidgets } from "../funcs/socialWidgets.js";

import { shortenAddress, copyToClipboard } from "../funcs/address.js";
/* global BigInt */

// Example object mapping contract addresses to social links
const socialLinksData = {
    "0x2835Ad9a421C14E1C571a5Bb492B86b7E8f5873A": {  // Example token CA
        twitter: "https://twitter.com/shidokid",
        telegram: "https://t.me/shido_kid",
        website: "https://www.shidokid.com",
        medium: "https://medium.com/@shidokid.fi",
        youtube: "https://www.youtube.com/@ShidoKid"
    },
    "0xDEF...456": {  // Another token CA
        twitter: "https://twitter.com/project456",
        telegram: null,  // No telegram link for this one
        website: "https://project456.com",
        medium: "https://medium.com/@project456",
        youtube: "https://youtube.com/"
    },
    // Add more tokens here...
};


export default function TokenDetails() {
    const { contractAddress } = useParams();
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [tokenInfo, setTokenInfo] = useState({ name: "", symbol: "", decimals: "18", totalSupply: "" });
    const [balance, setBalance] = useState("0");
    const [owner, setOwner] = useState("");
    const [recipient, setRecipient] = useState("");
    const [amount, setAmount] = useState("");
    const [spender, setSpender] = useState("");
    const [allowance, setAllowance] = useState("0");
    // State for collapsible sections
    const [activeModal, setActiveModal] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function init() {
            const walletData = await connectWallet();
            if (!walletData || !walletData.web3 || !walletData.account) return;

            setWeb3(walletData.web3);
            setAccount(walletData.account);
            const tokenContract = await getTokenSC(walletData.web3, contractAddress);
            setContract(tokenContract);

            const name = await tokenContract.methods.name().call();
            const symbol = await tokenContract.methods.symbol().call();
            const decimals = parseInt(await tokenContract.methods.decimals().call(), 10);
            console.log(decimals);
            const totalSupply = await tokenContract.methods.totalSupply().call();
            const owner = await tokenContract.methods.owner().call();
            const balance = await tokenContract.methods.balanceOf(walletData.account).call();

            let supplyParsed = totalSupply;
            let balanceParsed = balance;

            if (decimals > 0) {
                const divisor = BigInt(10) ** BigInt(decimals);

                supplyParsed = (BigInt(totalSupply) / divisor).toString();
                balanceParsed = (BigInt(balance) / divisor).toString();
            }

            setTokenInfo({ name, symbol, decimals, supplyParsed });
            setOwner(owner);
            setBalance(balanceParsed);
        }

        init();
        console.log("finished init")
    }, [contractAddress, account]);

    const handleTransfer = async () => {
        if (!contract || !web3) return;
        if (!amount || isNaN(amount)) {
            alert("Please enter a valid amount.");
            return;
        }

        let amountWei = amount;
        if (tokenInfo.decimals > 0) {
            amountWei = (BigInt(amount) * (BigInt(10) ** BigInt(tokenInfo.decimals))).toString();
        }

        await contract.methods.transfer(recipient, amountWei).send({ from: account });
        alert("Transfer successful!");
    };


    const handleBurn = async () => {
        /*if (!contract || !web3) return;

        let amountWei = amount;
        if (tokenInfo.decimals > 0) {
            amountWei = (BigInt(amount) * (BigInt(10) ** BigInt(tokenInfo.decimals))).toString();
        }

        await contract.methods.burn(amountWei).send({ from: account });
        alert("Tokens burned!");*/
        if (!contract || !web3) return;
        if (!amount || isNaN(amount)) {
            alert("Please enter a valid amount.");
            return;
        }

        let amountWei = amount;
        if (tokenInfo.decimals > 0) {
            amountWei = (BigInt(amount) * (BigInt(10) ** BigInt(tokenInfo.decimals))).toString();
        }

        await contract.methods.transfer("0x000000000000000000000000000000000000dEaD", amountWei).send({ from: account });
        alert("Burn successful!");
    };

    const handleApprove = async () => {
        if (!contract || !web3) return;

        let amountWei = amount;
        if (tokenInfo.decimals > 0) {
            amountWei = (BigInt(amount) * (BigInt(10) ** BigInt(tokenInfo.decimals))).toString();
        }

        await contract.methods.approve(spender, amountWei).send({ from: account });
        alert("Approval successful!");
    };

    const checkAllowance = async () => {
        if (!contract || !web3) return;
        if (!spender) {
            alert("Please enter a spender address.");
            return;
        }

        const result = await contract.methods.allowance(account, spender).call();

        let allowanceParsed = result;
        if (tokenInfo.decimals > 0) {
            allowanceParsed = (BigInt(result) / (BigInt(10) ** BigInt(tokenInfo.decimals))).toString();
        }

        setAllowance(allowanceParsed);
    };







    const handleTransferOwnership = async () => {
        if (!contract) return;
        const newOwner = prompt("Enter new owner address:");
        if (newOwner) {
            await contract.methods.transferOwnership(newOwner).send({ from: account });
            alert("Ownership transferred!");
        }
    };

    const handleRenounceOwnership = async () => {
        if (!contract) return;
        await contract.methods.renounceOwnership().send({ from: account });
        alert("Ownership renounced!");
    };

    return (
        <div className="token-details">
            <Navbar />
            <h1>{tokenInfo.name} ({tokenInfo.symbol})</h1>
            <b className="clickable-address" onClick={() => copyToClipboard(contractAddress)}>
                CA: {shortenAddress(contractAddress)}
            </b>
            <p><strong>Decimals:</strong> {tokenInfo.decimals.toString()}</p>
            <p><strong>Total Supply:</strong> {parseFloat(tokenInfo.supplyParsed).toFixed(0)}</p>
            <p><strong>Your Balance:</strong> {parseFloat(balance).toFixed(0)} {tokenInfo.symbol}</p>

            <h3>Actions</h3>
            <button onClick={() => setActiveModal("transfer")}>Transfer</button>
            <button onClick={() => setActiveModal("approve")}>Approve</button>
            <button onClick={() => setActiveModal("checkAllowance")}>Check Allowance</button>

            <button onClick={() => setActiveModal("burn")}>Burn</button>
            {account?.toLowerCase() === owner?.toLowerCase() && (
                <div className="admin-actions">
                    <h3>Owner Actions</h3>
                    <button onClick={() => setActiveModal("transferOwnership")}>Transfer Ownership</button>
                    <button onClick={() => setActiveModal("renounceOwnership")}>Renounce Ownership</button>
                </div>
            )}


            {/* MODALS */}
            {activeModal && (
                <div className="modal-overlay" onClick={() => setActiveModal(null)}>
                    <div className="modal-contents" onClick={(e) => e.stopPropagation()}>
                        <button className="modal-close" onClick={() => setActiveModal(null)}>✖</button>

                        {activeModal === "transfer" && (
                            <>
                                <h2>Transfer {tokenInfo.symbol}</h2>
                                <p><strong>Your Balance:</strong> {balance} {tokenInfo.symbol}</p>
                                <input type="text" placeholder="Recipient Address" value={recipient} onChange={(e) => setRecipient(e.target.value)} />
                                <input type="text" placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                <button onClick={handleTransfer}>Transfer</button>
                            </>
                        )}


                        {activeModal === "approve" && (
                            <>
                                <h2>Approve Spender for ${tokenInfo.symbol}</h2>
                                <input type="text" placeholder="Spender Address" value={spender} onChange={(e) => setSpender(e.target.value)} />
                                <input type="text" placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                <button onClick={handleApprove}>Approve</button>
                            </>
                        )}
                        {activeModal === "checkAllowance" && (
                            <>
                                <h2>Check ${tokenInfo.symbol} Allowance</h2>
                                <input type="text" placeholder="Spender Address" value={spender} onChange={(e) => setSpender(e.target.value)} />
                                <button onClick={checkAllowance}>Check Allowance</button>
                                <p>Allowance: {allowance} {tokenInfo.symbol}</p>
                            </>
                        )}

                        {activeModal === "burn" && (
                            <>
                                <h2>Burn ${tokenInfo.symbol}</h2>
                                <p><strong>Your Balance:</strong> {balance} {tokenInfo.symbol}</p>
                                <input type="text" placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                <button onClick={handleBurn}>Burn</button>
                            </>
                        )}

                        {activeModal === "transferOwnership" && (
                            <>
                                <h2>Transfer ${tokenInfo.symbol} Ownership</h2>
                                <button onClick={handleTransferOwnership}>Transfer Ownership</button>
                            </>
                        )}

                        {activeModal === "renounceOwnership" && (
                            <>
                                <h2>Renounce ${tokenInfo.symbol} Ownership</h2>
                                <button onClick={handleRenounceOwnership}>Renounce Ownership</button>
                            </>
                        )}
                    </div>

                </div>

            )}
            {getWidgets(socialLinksData[contractAddress] || {})}
            <p>Go Back to the previous page</p>
            <button onClick={() => navigate(-1)}>Return</button>

        </div>
    );
}
