import React, { createContext, useEffect, useState } from "react";
import { useWallet } from "../contexts/WalletContext";
import { TOKEN_ABI } from "../config";

export const BalanceContext = createContext();
const KIDDO = "0x2835Ad9a421C14E1C571a5Bb492B86b7E8f5873A";
export function BalanceProvider({ children }) {
    const { web3, account } = useWallet();
    const [userBalance, setUserBalance] = useState("0");
    const [userShidoBalance, setUserShidoBalance] = useState("0");
    const [allowances, setAllowances] = useState({}); // { spender: allowance }

    useEffect(() => {
        if (!web3 || !account) return;

        const token = new web3.eth.Contract(TOKEN_ABI, KIDDO);

        async function fetchBaseBalances() {
            try {
                const balance = await token.methods.balanceOf(account).call();
                const shido = await web3.eth.getBalance(account);

                setUserBalance(Number(web3.utils.fromWei(balance.toString(), "ether")).toFixed(6));
                setUserShidoBalance(Number(web3.utils.fromWei(shido.toString(), "ether")).toFixed(6));
            } catch (err) {
                console.error("Base balance fetch failed", err);
            }
        }

        fetchBaseBalances();
    }, [web3, account]);

    const fetchAllowance = async (spender) => {
        if (!web3 || !account || !spender) return "0";

        try {
            const token = new web3.eth.Contract(TOKEN_ABI, KIDDO);
            const raw = await token.methods.allowance(account, spender).call();
            const formatted = Number(web3.utils.fromWei(raw.toString(), "ether")).toFixed(6);
            setAllowances(prev => ({ ...prev, [spender]: formatted }));
            return formatted;
        } catch (err) {
            console.error("Allowance fetch failed", err);
            return "0";
        }
    };

    // useBalances.js
    const approveAllowance = async (spender, amount) => {
        if (!web3 || !account || !spender) {
            alert("Wallet not connected or missing spender.");
            return false;
        }

        try {
            const token = new web3.eth.Contract(TOKEN_ABI, KIDDO);
            await token.methods.approve(spender, amount).send({ from: account });

            const updated = await token.methods.allowance(account, spender).call(); // <- fetch new value after approve
            const formatted = Number(web3.utils.fromWei(updated.toString(), "ether")).toFixed(6);
            setAllowances(prev => ({ ...prev, [spender]: formatted })); // <- update internal state

            alert("Approval successful!");
            return true;
        } catch (err) {
            console.error("Approval error:", err);
            alert("Approval failed. Please try again.");
            return false;
        }
    };

    const refreshAllowance = async (spenderAddress) => {
        if (!account || !web3 || !spenderAddress) return;
        try {
            const token = new web3.eth.Contract(TOKEN_ABI, KIDDO);
            const rawAllowance = await token.methods.allowance(account, spenderAddress).call();
            const formatted = Number(web3.utils.fromWei(rawAllowance, "ether")).toFixed(6);
            setAllowances(prev => ({ ...prev, [spenderAddress]: formatted }));
        } catch (err) {
            console.error("Error refreshing allowance:", err);
        }
    };

    const refreshBalance = async () => {
        if (!web3 || !account) return;

        try {
            const token = new web3.eth.Contract(TOKEN_ABI, KIDDO);
            const kiddoBal = await token.methods.balanceOf(account).call();
            const shidoBal = await web3.eth.getBalance(account);

            setUserBalance(Number(web3.utils.fromWei(kiddoBal.toString(), "ether")).toFixed(6));
            setUserShidoBalance(Number(web3.utils.fromWei(shidoBal.toString(), "ether")).toFixed(6));
        } catch (err) {
            console.error("Error refreshing balances:", err);
        }
    };



    return (
        <BalanceContext.Provider value={{
            userBalance,
            userShidoBalance,
            allowances,
            fetchAllowance,
            approveAllowance,
            refreshAllowance,
            refreshBalance
        }}>
            {children}
        </BalanceContext.Provider>
    );
}
