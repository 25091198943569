import { useContext, useEffect, useState, useCallback } from "react";
import { BalanceContext } from "../providers/BalanceProvider";
import { useWallet } from "../contexts/WalletContext";
import { TOKEN_ABI } from "../config.js";


export function useBalances(spender = null) {
    const { userBalance, userShidoBalance, allowances, fetchAllowance, approveAllowance, refreshAllowance } = useContext(BalanceContext);
    const [userAllowance, setUserAllowance] = useState("0");

    useEffect(() => {
        if (!spender) {
            setUserAllowance("0");
            return;
        }

        async function fetch() {
            const val = allowances[spender] || await fetchAllowance(spender);
            setUserAllowance(val);
        }

        fetch();
    }, [spender, allowances, fetchAllowance]);



    return { userBalance, userShidoBalance, userAllowance, approveAllowance, refreshAllowance };
}


export function useTokenBalance(tokenAddress) {
    const { web3, account } = useWallet();
    const [balance, setBalance] = useState("0");

    const refreshTokenBalance = useCallback(async () => {
        if (!web3 || !account || !tokenAddress) return;
        const token = new web3.eth.Contract(TOKEN_ABI, tokenAddress);
        const raw = await token.methods.balanceOf(account).call();
        setBalance(web3.utils.fromWei(raw.toString(), "ether"));
    }, [web3, account, tokenAddress]);

    useEffect(() => {
        refreshTokenBalance();
    }, [refreshTokenBalance]);

    return { balance, refreshTokenBalance };
}

export function useTokenInfo(tokenAddress, spender) {
    const { web3, account } = useWallet();
    const [symbol, setSymbol] = useState("");
    const [decimals, setDecimals] = useState(18);
    const [allowance, setAllowance] = useState("0");

    const refreshInfo = useCallback(async () => {
        if (!web3 || !account || !tokenAddress) return;

        const token = new web3.eth.Contract(TOKEN_ABI, tokenAddress);
        const [sym, dec, rawAllowance] = await Promise.all([
            token.methods.symbol().call(),
            token.methods.decimals().call(),
            token.methods.allowance(account, spender).call()
        ]);

        setSymbol(sym);
        setDecimals(Number(dec));
        setAllowance(web3.utils.fromWei(rawAllowance, "ether"));
    }, [web3, account, tokenAddress, spender]);

    useEffect(() => {
        refreshInfo();
    }, [refreshInfo]);

    return { symbol, decimals, allowance, refreshInfo };
}

