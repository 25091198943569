import React, { createContext, useContext, useState, useEffect } from "react";
import Web3 from "web3";

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
    const [account, setAccount] = useState(null);
    const [web3, setWeb3] = useState(null);

    useEffect(() => {
        const initWeb3 = async () => {
            if (window.ethereum) {
                const instance = new Web3(window.ethereum);
                setWeb3(instance);

                const wasConnected = localStorage.getItem("connectedWallet");
                const selected = window.ethereum.selectedAddress;
                if (wasConnected && selected) {
                    setAccount(selected);
                }
            } else {
                // ✅ fallback read-only provider (optional)
                const fallback = new Web3("https://rpc.shido.io"); // replace with your chain’s public RPC
                setWeb3(fallback);
                console.warn("No wallet found. Using fallback Web3 provider.");
            }
        };

        initWeb3();
    }, []);

    const connectWallet = async () => {
        if (!window.ethereum) {
            alert("MetaMask not detected! Please install MetaMask.");
            return;
        }

        try {
            const targetChainId = "0x2330";
            const currentChainId = await window.ethereum.request({ method: "eth_chainId" });

            if (currentChainId !== targetChainId) {
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: targetChainId }],
                });
            }

            const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
            const instance = new Web3(window.ethereum);
            setWeb3(instance);
            setAccount(accounts[0]);
            localStorage.setItem("connectedWallet", "true");
        } catch (error) {
            console.error("Wallet connection error:", error);
            alert("Failed to connect wallet. Try again.");
        }
    };

    const disconnectWallet = () => {
        setAccount(null);
        localStorage.removeItem("connectedWallet");

        // Keep web3 instance active for global access
        if (window.ethereum) {
            const instance = new Web3(window.ethereum);
            setWeb3(instance);
        } else {
            const fallback = new Web3("https://rpc.shido.io");
            setWeb3(fallback);
        }
    };

    return (
        <WalletContext.Provider value={{ account, web3, connectWallet, disconnectWallet }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => useContext(WalletContext);
