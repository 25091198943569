import { Link } from "react-router-dom";
import "../css/FactoryOverview.css";

const FactoryOverview = () => {
    return (
        <div className="factory-overview-container">

            <div className="divcolor w80 tabs">
                <h1 className="main-title">🧪 Welcome to the ShidoKid Factory</h1>
                <p className="subtitle">Smart Contract Templates. Infinite Power. One click away.</p>
                <hr className="glow-divider" />
                <h2 className="section-title">🧠 Understanding Smart Contract Factories</h2>
                <div className="factory-info-cards">
                    <div className="info-card glass">
                        <h3>⚙️ What Is a Smart Contract Factory?</h3>
                        <p>Deploy pre-coded smart contracts effortlessly. Factories are your shortcut to powerful on-chain infrastructure — no Solidity needed.</p>
                    </div>

                    <div className="info-card glass">
                        <h3>🚀 Why Use ShidoKid’s Factory?</h3>
                        <p>Fast. Customizable. Battle-tested. Our Factory templates are optimized for speed, security, and scaling community ecosystems.</p>
                    </div>

                    <div className="info-card glass">
                        <h3>🧩 Endless Possibilities</h3>
                        <p>Launch tokens, Vaults, staking Contracts in under a minute. Our modular system evolves with your project’s needs.</p>
                    </div>
                </div>
                <hr className="glow-divider" />
                <h2 className="section-title">🛠️ Choose Your Factory</h2>
                <div className="factory-options mb5">
                    <Link to="/factory/token" className="factory-card">
                        <h2>Token Factory</h2>
                        <p>Create your ERC-20 tokens with custom options.</p>
                    </Link>

                    <Link to="/factory/vault" className="factory-card disabled">
                        <h2>Vault Factory <span className="soon-tag">Soon</span></h2>
                        <p>Create a custom token Vault and safely lock your community tokens!</p>
                    </Link>

                    {/*<Link to="/factory/staking" className="factory-card disabled">
                        <h2>Staking Factory <span className="soon-tag">Soon</span></h2>
                        <p>Create your own Staking Smart Contract!</p>
                    </Link>*/}
                </div>

            </div>



            <div className="factory-selector-section">

            </div>




        </div>
    );
};

export default FactoryOverview;
