// 📌 src/api/factoryAPI.js
export async function fetchFactoryData(factoryContract, web3) {
    try {
        const totalDeployedTokens = await factoryContract.methods.tokenDeploymentCount(1).call();
        const tokenTypeCount = await factoryContract.methods.tokenTypeCount().call();
        const totalNativeEarned = await factoryContract.methods.totalNativeEarned().call();
        const totalERC20Earned = await factoryContract.methods.totalERC20Earned().call();
        const owner = await factoryContract.methods.owner().call();

        return {
            totalDeployedTokens: totalDeployedTokens.toString(),
            tokenTypeCount,
            totalNativeEarned: web3.utils.fromWei(totalNativeEarned, "ether"),
            totalERC20Earned: web3.utils.fromWei(totalERC20Earned, "ether"),
            owner,
        };
    } catch (error) {
        console.error("Error fetching factory data:", error);
        return null;
    }
}


//src/api/factoryAPI.js
export async function fetchAllDeployedTokens(factoryContract, totalTokens) {
    if (!factoryContract || totalTokens === 0) return [];

    try {
        let tokens = [];
        for (let i = 0; i < totalTokens; i++) {
            const token = await factoryContract.methods.deployedTokens(i).call();
            tokens.push({
                address: token.tokenAddress,
                name: token.name,
                symbol: token.symbol,
                owner: token.owner,
            });
        }

        return tokens;
    } catch (error) {
        console.error("Error fetching all deployed tokens:", error);
        return [];
    }
}
