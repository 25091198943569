import { FaTelegramPlane, FaGlobe, FaMedium, FaYoutube } from "react-icons/fa"; // Import brand icons
import { BsTwitterX } from "react-icons/bs";

export const getWidgets = (links, size = 24) => {
    return (
        <div className="flex-center gap tabs mb5">
            {["twitter", "telegram", "website", "medium", "youtube"].map((platform) => {
                const link = links[platform];
                return (
                    <div key={platform} className="social-button-container">
                        <a
                            href={link || "#"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={link ? "social-active" : "social-disabled"}
                            onClick={(e) => !link && e.preventDefault()} // Prevent clicking if no link
                        >
                            {platform === "twitter" && <BsTwitterX size={size} />}
                            {platform === "telegram" && <FaTelegramPlane size={size} />}
                            {platform === "website" && <FaGlobe size={size} />}
                            {platform === "medium" && <FaMedium size={size} />}
                            {platform === "youtube" && <FaYoutube size={size} />}

                        </a>
                        {!link && <span className="tooltip">No link provided</span>}
                    </div>
                );
            })}
        </div>
    );
};