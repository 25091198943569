import React, { useState, useEffect } from "react";
//import Web3 from "web3";
//import { ABI } from "./abi.js";
import "../css/home.css"
import Navbar from "../widgets/NavBar"
import TextWithImage from "../funcs/myCheckbox";
import { getWidgets } from "../funcs/socialWidgets.js";

import SK from "./shidokid.webp"

const socialLinks = {  // Example token CA
    twitter: "https://twitter.com/shidokid",
    telegram: "https://t.me/shido_kid",
    website: "https://www.shidokid.com",
    medium: "https://medium.com/@shidokid.fi",
    youtube: "https://www.youtube.com/@ShidoKid"
}
const text = (

    "ShidoKid is a project built on the Shido Network, offering a suite of tools and services designed for projects, communities, and individual users creating and engaging with blockchain technology.\n\n\n"
    + "By leveraging KIDDO, its native token, alongside SHIDO Coin, ShidoKid fosters on - chain development, interaction, and decentralized innovation, expanding the ecosystem’s utility and accessibility."
)
function Home() {
    const [imgSize, setImgSize] = useState(420);


    useEffect(() => {
        const handleResize = () => {
            setImgSize(window.innerWidth <= 768 ? 200 : 420); // Smaller image size for mobile
        };

        handleResize(); // Set initial size
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className="home">
            <Navbar />
            <h1 className="mt5"> Welcome to ShidoKid</h1>
            <TextWithImage
                imgSrc={SK}
                text={text}
                imgSize={imgSize}
                paddingLeft={5}
                paddingRight={5}
            />

            <h2>Check us out on:</h2>
            {getWidgets(socialLinks, 50)}

        </div>
    );





}

export default Home;
