import BigNumber from "bignumber.js";  // Import the BigNumber library
import Web3 from "web3";
import JSBI from "jsbi";

export function formatAmount(value, decimals = 18, { suffix = "" } = {}) {
    try {
        const bn = BigNumber.isBigNumber(value) ? value : new BigNumber(value);
        const divisor = new BigNumber(10).pow(decimals);
        const formatted = bn.dividedBy(divisor).toFixed(4, 1).replace(/\.?0+$/, "");

        return `${addCommas(formatted)}${suffix ? " " + suffix : ""}`;
    } catch (err) {
        console.error("formatAmount error:", err);
        return "Invalid";
    }
}
// Add commas to numbers (e.g., 123456.78 -> 123,456.78)
function addCommas(value) {
    const parts = value.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export const toBI = (number) => {
    return JSBI.BigInt(number);
};

export const calculateShidoForMaxPurchase = (maxPerPurchase, rate) => {
    //const maxPurchaseBigInt = JSBI.BigInt(maxPerPurchase);
    //const rateBigInt = JSBI.BigInt(rate);

    // Adjust for decimals (if needed) and perform division
    const shidoNeededBigInt = maxPerPurchase / rate // JSBI.divide(maxPurchaseBigInt, rateBigInt);

    // Convert to float for display
    return convertBigIntToFloat(shidoNeededBigInt, 18); // Assuming 18 decimals
};

export const convertBigIntToFloat = (bigIntValue, decimals = 18) => {
    const SCALE = JSBI.exponentiate(toBI(10), toBI(decimals));
    return Number(bigIntValue.toString()) / Number(SCALE.toString());
};

export const parseAmountToWei = (amount) => {
    if (isNaN(amount) || Number(amount) <= 0) {
        throw new Error("Invalid amount.");
    }
    return new BigNumber(Web3.utils.toWei(amount.toString(), "ether"));
};

export const formatNumberWithCommas = (num) => {
    if (!num) return "0"; // Handle null or undefined

    const [integerPart, decimalPart] = num.toString().split(".");

    return (
        integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        (decimalPart ? "." + decimalPart : "")
    );
};
