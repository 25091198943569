import { formatAmount } from "../funcs/bigint.js";
import { useState } from "react";

function RecipientManager({
    recipients,
    setRecipients,
    totalAmount,
    setTotalAmount,
    onParseInputs,
    addressInput,
    setAddressInput,
    amountInput,
    setAmountInput,
    addRecipient,
    symbol = "SHIDO",
    decimals = 18
}) {
    const [inputMethod, setInputMethod] = useState(""); // State for toggling input methods

    const textareaStyle = {
        width: "100%",
        height: "100px", // Fixed height
        marginBottom: "10px", // Space between textareas
        resize: "none", // Disable resizing
        color: "white",
        background: "linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.02))",
        borderRadius: "8px"
    };

    return (
        <div className="batch-container w100">

            <div className="recipient-list tabs w80 mb5">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2 className="myh3 stats-title">🔢 Select Input Method</h2>

                    <div className="dropdown-container">
                        <select
                            value={inputMethod}
                            onChange={(e) => setInputMethod(e.target.value)}
                            style={{
                                padding: "10px",
                                fontSize: "16px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                                marginBottom: "20px",
                            }}
                        >
                            <option value="" disabled>
                                -- Select Method --
                            </option>
                            <option value="manual">Add Manually</option>
                            <option value="bulk">Add in Bulk</option>
                        </select>
                    </div>


                </div>
                <h3>Recipient List</h3>
                {recipients.map((r, index) => (
                    <div key={index} className="recipient-entry">
                        <p key={index} className="recipient-entry">
                            {index + 1}. {r.address} - {formatAmount(r.amount.toString())} {symbol}
                            <button
                                className="remove-btn"
                                onClick={() => {
                                    const updated = recipients.filter((_, i) => i !== index);
                                    setRecipients(updated);
                                    setTotalAmount(totalAmount.minus(r.amount));
                                }}
                            >
                                ❌
                            </button>
                        </p>

                    </div>
                ))}

                <div className="input-method-container">
                    {inputMethod === "manual" && (
                        <div className="recipient-add">
                            <h5 className="text-primary">Add Recipients One by One</h5>
                            <button className="add-btn" onClick={addRecipient}>
                                Add Recipient
                            </button>
                        </div>
                    )}

                    {inputMethod === "bulk" && (
                        <div className="alternate-input">
                            <h5 className="text-primary">Input addresses/amounts separated by commas</h5>
                            <textarea
                                style={textareaStyle}
                                placeholder="Enter addresses (comma separated)"
                                value={addressInput}
                                onChange={(e) => setAddressInput(e.target.value)}
                            />
                            <textarea
                                style={textareaStyle}
                                placeholder="Enter amounts (comma separated)"
                                value={amountInput}
                                onChange={(e) => setAmountInput(e.target.value)}
                            />
                            <button className="parse-btn" onClick={onParseInputs}>
                                Parse Inputs
                            </button>
                        </div>

                    )}
                </div>

            </div>
        </div>
    );
}

export default RecipientManager;
