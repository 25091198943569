import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreSale from "./pages/preSale";
import Home from "./pages/home";
import Tools from "./pages/tools";
import BatchShi20 from "./pages/batchShi20";
import BatchShido from "./pages/batchShido";
import StashWidget from "./pages/stash.js";
import TagBattleWidget from "./pages/TagBattleWidget.js";
import Staking from "./pages/staking.js";
import StakingOG from "./pages/stakingOG.js";
import ERC20Widget from "./pages/ERC20.js";
import LockTokens from "./pages/StashNew.js";
import TokenFactoryUI from "./pages/TokenFactoryUI.js";
import AllTokensPage from "./pages/tokens.js";
import TokenDetails from "./pages/TokenDetails.js";
import Navbar from "./widgets/NavBar.js"; // Import Navbar
import { WalletProvider } from "./contexts/WalletContext"; // Import Global Wallet State
import FactoryOverview from "./pages/FactoryOverview.js"
import { BalanceProvider } from "./providers/BalanceProvider";

import "./css/App.css";

function App() {
  return (
    <WalletProvider>
      <BalanceProvider>
        <Router>
          <div className="App">
            <Navbar /> {/* Now all pages will have the login/logout button */}
            <Routes>
              <Route path="/seed-round" element={<PreSale />} />
              <Route path="/tools" element={<Tools />} />
              <Route path="/tools/batch-shi20" element={<BatchShi20 />} />
              <Route path="/tools/batch-shido" element={<BatchShido />} />
              <Route path="/stash" element={<StashWidget />} />
              <Route path="/tag" element={<TagBattleWidget />} />
              <Route path="/erc20" element={<ERC20Widget />} />
              <Route path="/" element={<Home />} />
              <Route path="/staking" element={<StakingOG />} />
              <Route path="/stake" element={<Staking />} />
              <Route path="/test" element={<LockTokens />} />
              <Route path="/factory" element={<FactoryOverview />} />
              <Route path="/factory/token" element={<TokenFactoryUI />} />
              <Route path="/token" element={<AllTokensPage />} />
              <Route path="/token/:contractAddress" element={<TokenDetails />} />
            </Routes>
          </div>
        </Router>
      </BalanceProvider>

    </WalletProvider>
  );
}

export default App;
