import React, { useState } from "react";
import Web3 from "web3";
import CustomModal from "../widgets/modal";
import RecipientManager from "../widgets/RecipientManager";
import { formatNumberWithCommas } from "../funcs/bigint";
import BigNumber from "bignumber.js";

function BatchTransferTemplate({
    title,
    symbol,
    decimals,
    balance,
    allowance,
    isNative = false,
    onApprove,
    onSend,
    refreshBalance,
    contractReady,
    showTokenAddressInput = false,
    onTokenAddressChange,
    tokenAddress,
}) {
    const [recipients, setRecipients] = useState([]);
    const [totalAmount, setTotalAmount] = useState(new BigNumber(0));
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [newRecipient, setNewRecipient] = useState({ address: "", amount: "" });
    const [addressInput, setAddressInput] = useState("");
    const [amountInput, setAmountInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [txnHash, setTxnHash] = useState("");
    const [sendError, setSendError] = useState("");

    const resetForm = () => {
        setRecipients([]);
        setTotalAmount(new BigNumber(0));
        setNewRecipient({ address: "", amount: "" });
        setAddressInput("");
        setAmountInput("");
    };

    const formatToken = (value) => {
        const formatted = new BigNumber(value)
            .dividedBy(new BigNumber(10).pow(decimals))
            .toFixed(4);
        return formatNumberWithCommas(formatted);
    };

    const handleModalSubmit = () => {
        const { address, amount } = newRecipient;
        const amountWei = new BigNumber(amount).multipliedBy(new BigNumber(10).pow(decimals));
        if (Web3.utils.isAddress(address) && amountWei.gt(0)) {
            setRecipients([...recipients, { address, amount: amountWei }]);
            setTotalAmount(totalAmount.plus(amountWei));
            setNewRecipient({ address: "", amount: "" });
            setModalIsOpen(false);
        } else {
            alert("Invalid recipient address or amount.");
        }
    };

    const handleInputParse = () => {
        const addressArray = addressInput.split(",").map((address) => address.trim());
        const amountArray = amountInput.split(",").map((amount) => amount.trim());

        if (addressArray.length !== amountArray.length) {
            alert("The number of addresses and amounts do not match.");
            return;
        }

        const parsedRecipients = [];
        let total = new BigNumber(0);

        for (let i = 0; i < addressArray.length; i++) {
            const address = addressArray[i];
            const amount = new BigNumber(amountArray[i]);
            if (!Web3.utils.isAddress(address) || amount.lte(0)) {
                alert(`Invalid entry at index ${i}`);
                return;
            }
            const amountWei = amount.multipliedBy(new BigNumber(10).pow(decimals));
            parsedRecipients.push({ address, amount: amountWei });
            total = total.plus(amountWei);
        }

        setRecipients([...recipients, ...parsedRecipients]);
        setTotalAmount(totalAmount.plus(total));
    };

    const handleSend = async () => {
        if (!contractReady || recipients.length === 0) return;
        if (new BigNumber(balance).lt(totalAmount)) {
            alert("Insufficient balance");
            return;
        }

        if (!isNative && allowance && totalAmount.gt(allowance)) {
            alert("Please approve the token first.");
            return;
        }

        setLoading(true);
        try {
            const txHash = await onSend(recipients, totalAmount);
            if (txHash) setTxnHash(txHash);
            resetForm();
            if (refreshBalance) await refreshBalance();
        } catch (err) {
            console.error(err);
            setSendError("Transaction failed");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="batch-template tabs fvw">
            <div className="glass box center-text pad20 w80 tabs mt5">
                <h1 className="text-3xl font-bold">{title}</h1>

                {showTokenAddressInput && (
                    <>
                        <h3>Token Contract Address</h3>
                        <input
                            className="input-field"
                            type="text"
                            placeholder="Enter token contract address"
                            value={tokenAddress}
                            onChange={onTokenAddressChange}
                        />
                    </>
                )}

                {!showTokenAddressInput && !isNative && (
                    <button
                        className="change-token-btn mt2"
                        onClick={() => onTokenAddressChange({ target: { value: "" } })}
                    >
                        🔄 Change Token
                    </button>
                )}

                <p className="text-primary">
                    Balance: {formatToken(balance)} {symbol}
                </p>

                {!isNative && (
                    <p className="text-secondary">
                        Allowance: {formatToken(allowance)} {symbol}
                    </p>
                )}

                {txnHash && (
                    <p className="txn-hash">
                        Transaction Hash:{" "}
                        <a href={`https://shidoscan.net/tx/${txnHash}`} target="_blank" rel="noopener noreferrer">
                            {txnHash}
                        </a>
                    </p>
                )}
            </div>

            <div className="batch-transfer-logged divcolor w80 tabs mt5">
                {recipients.length > 0 && (
                    <div className="txn-summary">
                        {totalAmount.gt(balance) && <h1 className="error">Balance Overdrawn</h1>}
                        <p>Total Recipients: {recipients.length}</p>
                        <p>Total Amount: {formatToken(totalAmount)} {symbol}</p>
                        {sendError && <p className="error-text">{sendError}</p>}
                        {isNative || allowance.gte(totalAmount) ? (
                            <button className="send-btn" onClick={handleSend} disabled={loading}>
                                {loading ? "Sending..." : "Send"}
                            </button>
                        ) : (
                            <button className="approve-btn" onClick={onApprove}>
                                Approve
                            </button>
                        )}
                        <button className="clear-btn" onClick={resetForm}>
                            Clear Recipients
                        </button>
                    </div>
                )}

                <RecipientManager
                    recipients={recipients}
                    setRecipients={setRecipients}
                    totalAmount={totalAmount}
                    setTotalAmount={setTotalAmount}
                    onParseInputs={handleInputParse}
                    addressInput={addressInput}
                    setAddressInput={setAddressInput}
                    amountInput={amountInput}
                    setAmountInput={setAmountInput}
                    addRecipient={() => setModalIsOpen(true)}
                    symbol={symbol}
                    decimals={decimals}
                />
            </div>

            <CustomModal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
                <h3>Add Recipient</h3>
                <input
                    type="text"
                    placeholder="Recipient Address"
                    value={newRecipient.address}
                    onChange={(e) => setNewRecipient({ ...newRecipient, address: e.target.value })}
                />
                <input
                    type="number"
                    placeholder="Amount"
                    value={newRecipient.amount}
                    onChange={(e) => setNewRecipient({ ...newRecipient, amount: e.target.value })}
                />
                <button onClick={handleModalSubmit}>Submit</button>
                <button onClick={() => setModalIsOpen(false)}>Close</button>
            </CustomModal>
        </div>
    );
}

export default BatchTransferTemplate;
