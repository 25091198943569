import React, { useEffect, useState, useCallback } from "react";
import Web3 from "web3";
import Navbar from "../widgets/NavBar";
import BatchTransferTemplate from "../widgets/BatchTransferTemplate";
import { useWallet } from "../contexts/WalletContext.js";
import { getBatchTransfers, getTokenSC } from "../funcs/getContract.js";
import BigNumber from "bignumber.js";
import { useSearchParams } from "react-router-dom";


const CONTRACT_ADDRESS = "0x1AD0D74967d8c91d88D88aA229a5DAf3e46538B6";

function BatchShi20() {
    const { web3, account } = useWallet();
    const [contract, setContract] = useState(null);
    const [tokenContract, setTokenContract] = useState(null);
    const [tokenAddress, setTokenAddress] = useState("");
    const [hasTokenLoaded, setHasTokenLoaded] = useState(false);
    const [decimals, setDecimals] = useState(18);
    const [balance, setBalance] = useState({ amount: new BigNumber(0), symbol: "" });
    const [allowance, setAllowance] = useState(new BigNumber(0));
    const [error, setError] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const init = async () => {
            if (web3 && account) {
                const instance = getBatchTransfers(web3);
                setContract(instance);
            }
        };
        init();
    }, [account, web3]);



    const handleTokenAddressChange = useCallback((e) => {
        const input = typeof e === "string" ? e : e.target.value.trim();
        setTokenAddress(input);
        setSearchParams({ token: input });

        if (Web3.utils.isAddress(input)) {
            const fetchToken = async () => {
                try {
                    const tokenInstance = await getTokenSC(web3, input);
                    setTokenContract(tokenInstance);
                    const [bal, sym, allow, dec] = await Promise.all([
                        tokenInstance.methods.balanceOf(account).call(),
                        tokenInstance.methods.symbol().call(),
                        tokenInstance.methods.allowance(account, CONTRACT_ADDRESS).call(),
                        tokenInstance.methods.decimals().call(),
                    ]);

                    setBalance({ amount: new BigNumber(bal), symbol: sym });
                    setAllowance(new BigNumber(allow));
                    setDecimals(Number(dec));
                    setError("");
                    setHasTokenLoaded(true); // ✅ mark as loaded
                } catch (err) {
                    console.error("Token fetch error:", err);
                    setError("Could not fetch token details.");
                    setTokenContract(null);
                    setHasTokenLoaded(false);
                }
            };
            fetchToken();
        } else {
            setError("Invalid token address.");
            setTokenContract(null);
            setHasTokenLoaded(false);
        }
    }, [web3, account, setSearchParams]);

    useEffect(() => {
        const tokenFromUrl = searchParams.get("token");
        if (tokenFromUrl && Web3.utils.isAddress(tokenFromUrl)) {
            handleTokenAddressChange({ target: { value: tokenFromUrl } });
        }
    }, [searchParams, handleTokenAddressChange]);

    const approveTokens = async (recipients, totalAmount) => {
        try {
            await tokenContract.methods
                .approve(CONTRACT_ADDRESS, totalAmount.toFixed(0))
                .send({ from: account });

            const newAllowance = await tokenContract.methods.allowance(account, CONTRACT_ADDRESS).call();
            setAllowance(new BigNumber(newAllowance));
        } catch (err) {
            console.error("Approval error:", err);
            alert("Token approval failed.");
        }
    };

    const sendTokens = async (recipients, totalAmount) => {
        const addresses = recipients.map((r) => r.address);
        const amounts = recipients.map((r) => new BigNumber(r.amount).toFixed(0));

        const tx = await contract.methods
            .batchTransferERC20(tokenAddress, addresses, amounts)
            .send({ from: account });

        const updatedBalance = await tokenContract.methods.balanceOf(account).call();
        const updatedAllowance = await tokenContract.methods.allowance(account, CONTRACT_ADDRESS).call();
        setBalance((prev) => ({ ...prev, amount: new BigNumber(updatedBalance) }));
        setAllowance(new BigNumber(updatedAllowance));

        return tx.transactionHash;
    };

    return (
        <div className="batch-shi20 fvw">
            <Navbar />

            {tokenContract && (
                <BatchTransferTemplate
                    title={`📤 Batch ${balance.symbol || "Token"} Transfers`}
                    symbol={balance.symbol}
                    decimals={decimals}
                    balance={balance.amount}
                    allowance={allowance}
                    isNative={false}
                    contractReady={!!contract}
                    onApprove={approveTokens}
                    onSend={sendTokens}
                    showTokenAddressInput={!hasTokenLoaded}
                    tokenAddress={tokenAddress}
                    onTokenAddressChange={() => {
                        setTokenAddress("");
                        setTokenContract(null);
                        setHasTokenLoaded(false);
                        setSearchParams({});
                    }}
                />

            )}

            {!hasTokenLoaded && (
                <div className="glass box center-text pad20 w80 tabs mt5">
                    <h1 className="text-3xl font-bold">📤 Batch Shi20 Transfers</h1>
                    <p className="text-secondary">Connected as: {account}</p>
                    <h3>Token Contract Address</h3>
                    <input
                        className="input-field"
                        type="text"
                        placeholder="Enter token contract address"
                        value={tokenAddress}
                        onChange={handleTokenAddressChange}
                    />
                    {error && <p className="error">{error}</p>}
                </div>
            )}



        </div>
    );
}

export default BatchShi20;
