import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/tools.css"
import "../css/basix.css"
import Navbar from "../widgets/NavBar"

function Tools() {
    const navigate = useNavigate();


    return (
        <div className="tools">
            <Navbar />
            <h1 className="mt5" id="tools-title mt5" >ShidoKid Tools</h1>
            <div className="tools-grid">
                <div className="divcolor tool-container" onClick={() => navigate("/tools/batch-shido")} >
                    <h2>Batch Shido Transfers</h2>
                    <p className="tool-description">Have you ever run a giveaway or had to send SHIDO to multiple addresses? Did you sent each transaction one by one? with this tool you can do it with a single transaction.</p>
                    <h2>Send SHIDO Coins in bulk</h2>
                </div>
                <div className="divcolor tool-container" onClick={() => navigate("/tools/batch-shi20")} >
                    <h2>Batch SHI20 Transfers</h2>
                    <p className="tool-description">Send your favourite community tokens to multiple addresses and amounts, with ease.</p>
                    <h2>Send SHI20 Tokens in bulk</h2>
                </div>


            </div>
        </div >
    );
}

export default Tools;
