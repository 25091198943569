import React from "react";
import FactoryModalLayout from "./FactoryModalLayout";
import Navbar from "./NavBar";

import { useNavigate } from "react-router-dom";
export default function FactoryTemplate({
    title,
    description,
    stats = [],
    modalOpen,
    setModalOpen,
    formStep,
    setFormStep,
    renderModalStep1,
    renderModalStep2,
    renderTokens,
    renderAdminPanel
}) {
    const navigate = useNavigate();

    return (
        <div className="token-factory w100 tabs">
            <Navbar />

            <div className="divcolor w80 tabs mt5">
                <h1>{title}</h1>

                {stats.length > 0 && (
                    <div className="stats-grid pad20 glass w60 tabs">
                        {stats.map(({ label, value }) => (
                            <p key={label}><strong>{label}:</strong> {value}</p>
                        ))}
                    </div>
                )}

                {description && <p className="text-primary">{description}</p>}

                <h2 className="divcolor w40 tabs pad15 mb5"><strong>Build with ShidoKid</strong></h2>

                {!modalOpen && <button className="view-tokens-button mb5" onClick={() => navigate("/token")}>View All Deployed Tokens</button>}
                {!modalOpen && <button className="create-token-button mb5" onClick={() => setModalOpen(true)}>Create New Token</button>}
            </div>

            <FactoryModalLayout
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                step={formStep}
                setStep={setFormStep}
                renderStep1={renderModalStep1}
                renderStep2={renderModalStep2}
            />

            {renderTokens && renderTokens()}

            {renderAdminPanel && renderAdminPanel()}
        </div>
    );
}
