import React, { useState, useEffect, useCallback } from "react";
import "../css/staking.css"
import "../css/FeeTable.css"
import ProgressBar from "../widgets/progress";
import { convertBigIntToFloat, formatNumberWithCommas } from "../funcs/bigint";
import Countdown from "../widgets/countdown"; // Adjust the import path as necessary
import Navbar from "../widgets/NavBar"; import { getStakingOG } from "../funcs/getContract.js"
import { useWallet } from "../contexts/WalletContext.js"; // adjust if needed

const CONTRACT_ADDRESS = "0xbf2019c320AD99F7A84664c72599D772C225eF62";


function StakingOG() {
    const { web3, account } = useWallet();

    const [contract, setContract] = useState(null);
    const [tokenContract, setTokenContract] = useState(null);
    const [stakeAmount, setStakeAmount] = useState("");
    const [maxStakeable, setMaxStakeable] = useState("");
    const [userStake, setUserStake] = useState(0);
    const [totalStaked, setTotalStaked] = useState(0);
    const [userBalance, setUserBalance] = useState(0);
    const [allowance, setAllowance] = useState(0);
    const [rewardPool, setRewardPool] = useState(0);
    const [statusMessage, setStatusMessage] = useState("");
    const [apy, setApy] = useState("8");
    const [end, setEnd] = useState("");
    const [rewardAmount, setRewardAmount] = useState(""); // Input for adding rewards
    const [claimableRewards, setClaimableRewards] = useState(""); // Input for rewards
    const [stakingHasStarted, setStakingHasStarted] = useState(false); // Check if staking has started
    const [remainingRewards, setRemainingRewards] = useState(""); // Remaining rewards needed
    const [accumulatedFees, setAccumulatedFees] = useState("");
    const [stakeFee, setStakeFee] = useState("");
    const [claimFee, setClaimFee] = useState("");
    const [unstakeFee, setUnstakeFee] = useState("");
    const [currentMaxStakeable, setCurrentMaxStakeable] = useState("");
    const [activeTab, setActiveTab] = useState("overview");

    const ADMIN_ADDRESS = "0x062f444E491016dd87a46009798956C910108E40";
    const isAdmin = (account || "").toLowerCase() === ADMIN_ADDRESS.toLowerCase();


    const fetchGlobalData = useCallback(async () => {
        if (!web3) return;
        const toETH = (val) => web3.utils.fromWei(val.toString(), "ether"); // 👈 defined here

        try {
            const { stakingContract, kiddoContractInstance } = await getStakingOG(web3);
            setContract(stakingContract);
            setTokenContract(kiddoContractInstance);

            const [
                totalStakedRaw,
                maxStakeableRaw,
                apy,
                startTime,
                endTime,
                rewardPoolRaw,
                accumulatedFeesRaw,
                stakeFeeRaw,
                claimFeeRaw,
                unstakeFeeRaw,
                remainingRaw
            ] = await Promise.all([
                stakingContract.methods.totalStaked().call(),
                stakingContract.methods.maxStakeable().call(),
                stakingContract.methods.apy().call(),
                stakingContract.methods.startTime().call(),
                stakingContract.methods.endTime().call(),
                stakingContract.methods.rewardBalance().call(),
                stakingContract.methods.accumulatedFees().call(),
                stakingContract.methods.stakeFee().call(),
                stakingContract.methods.claimFee().call(),
                stakingContract.methods.unstakeFee().call(),
                stakingContract.methods.getRemainingRewards().call(),
            ]);

            // ✅ Convert to human-readable ETH
            const totalStakedETH = toETH(totalStakedRaw);
            const maxStakeableETH = toETH(maxStakeableRaw);
            const rewardPool = toETH(rewardPoolRaw);
            const accumulatedFees = toETH(accumulatedFeesRaw);
            const stakeFee = toETH(stakeFeeRaw);
            const claimFee = toETH(claimFeeRaw);
            const unstakeFee = toETH(unstakeFeeRaw);
            const remainingRewards = toETH(remainingRaw);

            // ✅ Safe subtraction in ETH format
            const currentMax = (parseFloat(maxStakeableETH) - parseFloat(totalStakedETH)).toFixed(0);

            // ✅ Set state
            setTotalStaked(parseFloat(totalStakedETH).toFixed(0));
            setMaxStakeable(parseFloat(maxStakeableETH).toFixed(0));
            setCurrentMaxStakeable(currentMax);
            setRewardPool(parseFloat(rewardPool).toFixed(0));
            setAccumulatedFees(accumulatedFees);
            setStakeFee(stakeFee);
            setClaimFee(claimFee);
            setUnstakeFee(unstakeFee);
            setRemainingRewards(remainingRewards);
            setApy(apy);
            setEnd(endTime);
            setStakingHasStarted(parseInt(startTime) <= Math.floor(Date.now() / 1000));
        } catch (err) {
            console.error("Error loading global staking data", err);
        }
    }, [web3]);






    const fetchUserData = useCallback(async () => {
        if (!account || !web3.utils.isAddress(account)) return;
        const toETH = (val) => web3.utils.fromWei(val.toString(), "ether"); // 👈 defined here

        try {
            //await fetchGlobalData(); // ⬅️ Refresh global values too

            if (!contract || !tokenContract) return;

            const [
                balanceRaw,
                allowanceRaw,
                rewardsRaw,
                userStakeRaw
            ] = await Promise.all([
                tokenContract.methods.balanceOf(account).call(),
                tokenContract.methods.allowance(account, CONTRACT_ADDRESS).call(),
                contract.methods.getClaimableRewards(account).call(),
                contract.methods.stakes(account).call()
            ]);

            setUserBalance(toETH(balanceRaw));
            setAllowance(toETH(allowanceRaw));
            setClaimableRewards(toETH(rewardsRaw));
            setUserStake(toETH(userStakeRaw.amount));

        } catch (error) {
            console.error("Error fetching user data:", error);
            setStatusMessage("Failed to fetch user data.");
        }
    }, [account, web3, contract, tokenContract]);


    useEffect(() => {
        fetchGlobalData(); // Load global data on mount
    }, [fetchGlobalData]);

    useEffect(() => {
        if (contract && tokenContract && account) {
            fetchUserData(); // Fetch full data after login
        }
    }, [contract, tokenContract, account, fetchUserData]);

    // Approve tokens
    const handleApprove = async (minValue) => {
        try {
            const maxApproval = web3.utils.toWei(minValue, "ether"); // Arbitrary high value for max approval
            await tokenContract.methods.approve(CONTRACT_ADDRESS, maxApproval).send({ from: account });
            setStatusMessage("Tokens approved successfully!");
            fetchUserData();
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to approve tokens.");
        }
    };



    // Stake tokens
    const handleStake = async () => {
        if (!stakeAmount || parseFloat(stakeAmount) <= 0) {
            setStatusMessage("Please enter a valid amount to stake.");
            return;
        }

        try {
            const amountInWei = web3.utils.toWei(stakeAmount, "ether");

            // Call the stake function
            await contract.methods.stake(amountInWei).send({ from: account, value: web3.utils.toWei(stakeFee, "ether") });
            setStatusMessage("Tokens staked successfully!");
            fetchUserData();
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to stake tokens.");
        }
    };

    // Unstake tokens
    const handleClaim = async () => {
        try {
            await contract.methods.claimRewards().send({ from: account, value: web3.utils.toWei(claimFee, "ether") });
            setStatusMessage("Tokens claimed successfully!");
            fetchUserData();
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to claim tokens.");
        }
    };

    const handleUnstake = async () => {
        try {
            await contract.methods.unstake().send({ from: account, value: web3.utils.toWei(unstakeFee, "ether") });
            setStatusMessage("Tokens unstaked successfully!");
            fetchUserData();
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to unstake tokens.");
        }
    };

    // Function to add rewards to the staking pool
    const handleAddRewards = async () => {
        if (!rewardAmount || parseFloat(rewardAmount) <= 0) {
            setStatusMessage("Enter a valid reward amount.");
            return;
        }

        try {
            const amountInWei = web3.utils.toWei(rewardAmount, "ether");

            // Call addRewards function in the contract
            await contract.methods.addRewards(amountInWei).send({ from: account });
            setStatusMessage("Rewards added successfully!");
            fetchUserData(); // Refresh contract state after updating rewards
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to add rewards.");
        }
    };

    // Function to withdraw excess rewards after the staking period
    const handleWithdrawExcessRewards = async () => {
        try {
            // Call withdrawExcessRewards function in the contract
            await contract.methods.withdrawExcessRewards().send({ from: account });
            setStatusMessage("Excess rewards withdrawn successfully!");
            fetchUserData(); // Refresh contract state after withdrawal
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to withdraw excess rewards.");
        }
    };

    const handleWithdrawFees = async () => {
        try {
            // Call withdrawExcessRewards function in the contract
            await contract.methods.withdrawFees().send({ from: account });
            setStatusMessage("Fees withdrawn successfully!");
            fetchUserData(); // Refresh contract state after withdrawal
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to withdraw excess rewards.");
        }
    };

    const renderOverview = () => (
        <div className="box stats-container w80 tabs">
            <h3 className="myh3 stats-title">📊 Staking Pool Stats</h3>
            <Countdown endTimestamp={parseInt(end)} />

            <div className="stats-grid pad20">
                <div className="glass stat-card pad15">🔥 APY: <strong>{convertBigIntToFloat(apy, 0)}%</strong></div>
                <div className="glass stat-card pad15 col-span-2">🏆 Reward Pool: <strong>{formatNumberWithCommas(rewardPool)}</strong></div>
            </div>
            <div className="stats-grid pad20">
                <div className="glass stat-card pad15">📈 Max Stakeable: <strong>{formatNumberWithCommas(maxStakeable)}</strong></div>
                <div className="glass stat-card pad15">💰 Total Staked: <strong>{formatNumberWithCommas(totalStaked)}</strong></div>
                <div className="glass stat-card pad15">🔹 KIDDO from Max: <strong>{formatNumberWithCommas(currentMaxStakeable)}</strong></div>
            </div>
        </div>
    );



    const renderMyStaking = () => (
        <div className="box stats-container w80 tabs">
            <h3 className="myh3 stats-title">👤 Your Staking Stats</h3>
            {account ? (
                <div>
                    <div className="stats-grid pad20">
                        <div className="glass stat-card pad15">📌 Staked: <strong>{formatNumberWithCommas(userStake)}</strong></div>
                        <div className="glass stat-card pad15">🏆 Your Rewards: <strong>{formatNumberWithCommas(claimableRewards)}</strong></div>
                    </div>
                    <div className="stats-grid pad20">
                        <div className="glass stat-card pad15">💰 KIDDO Balance: <strong>{formatNumberWithCommas(userBalance)}</strong></div>

                    </div>
                </div>

            ) : (
                <p className="center-text"><strong>⚠️ Not logged in.</strong> Connect your wallet to view your staking details.</p>
            )}
        </div>
    );


    const renderActions = () => {
        return (
            <div className="tabs box p-4 flex flex-col items-center w80">
                <h3 className="text-lg font-bold">Actions</h3>
                {account ? (
                    stakingHasStarted ? (
                        <>
                            <input type="number" placeholder="Enter amount to stake" value={stakeAmount} onChange={(e) => setStakeAmount(e.target.value)} className="input mt-2 w80" />
                            {parseFloat(stakeAmount || "0") > parseFloat(allowance || "0") ? (
                                <button onClick={() => handleApprove(stakeAmount)}>Approve</button>
                            ) : (
                                <button onClick={handleStake}>Stake</button>
                            )}
                            <button onClick={handleClaim}>Claim Rewards</button>
                            <button onClick={handleUnstake}>Unstake</button>
                        </>
                    ) : (
                        <p>Staking has not started yet. Please wait for the start time.</p>
                    )
                ) : (
                    <p><strong>Login required:</strong> Connect your wallet to perform staking actions.</p>
                )}
            </div>
        );
    };

    const renderFees = () => (
        <div className="box stats-container w80 tabs">
            <h3 className="myh3 stats-title">💰 Staking Fees</h3>
            <div className="stats-grid pad20">
                <div className="glass stat-card pad15">🔹 Stake Fee: <strong>{formatNumberWithCommas(stakeFee)} SHIDO</strong></div>
                <div className="glass stat-card pad15">💸 Claim Fee: <strong>{formatNumberWithCommas(claimFee)} SHIDO</strong></div>
                <div className="glass stat-card pad15">📤 Unstake Fee: <strong>{formatNumberWithCommas(unstakeFee)} SHIDO</strong></div>
            </div>
        </div>
    );

    const renderAdmin = () => {
        return (
            <div className="tabs box p-4 w80">
                <h3 className="text-lg font-bold">Admin Panel</h3>
                <p><strong>Rewards Claimed:</strong> {formatNumberWithCommas(remainingRewards)} SHIDO</p>
                <p><strong>Accumulated Fees:</strong> {formatNumberWithCommas(accumulatedFees)} SHIDO</p>
                <input type="number" placeholder="Enter reward amount" value={rewardAmount} onChange={(e) => setRewardAmount(e.target.value)} className="input mt-2" />
                {parseFloat(rewardAmount || "0") > parseFloat(userStake || "0") ? (
                    <button onClick={() => handleApprove(rewardAmount)}>Approve</button>
                ) : (
                    <button onClick={handleAddRewards}>Add Rewards</button>
                )}
                <button onClick={handleWithdrawFees}>Withdraw Fees</button>
                <button onClick={handleWithdrawExcessRewards}>Withdraw Excess Rewards</button>
            </div>
        );
    }

    return (
        <div className="fvw min-h-screen text-primary mb5">
            <Navbar />
            {statusMessage && (
                <div className="status-message box p-3 w80">
                    <p>{statusMessage}</p>
                </div>
            )}

            <h1 className="text-3xl font-extrabold my-4 mt5">KIDDO Staking</h1>
            <p className="text-center text-secondary">Connected as: {account || "Not Logged In"}</p>
            <ProgressBar current={totalStaked} max={maxStakeable} />

            {/* Tab Navigation */}
            <div className="tabs box navigation-bar pad10 w80">
                <button className={activeTab === "overview" ? "active" : ""} onClick={() => setActiveTab("overview")}>📊 Overview</button>
                <button className={activeTab === "my-staking" ? "active" : ""} onClick={() => setActiveTab("my-staking")}>👤 My Staking</button>
                <button className={activeTab === "actions" ? "active" : ""} onClick={() => setActiveTab("actions")}>⚡ Actions</button>
                <button className={activeTab === "fees" ? "active" : ""} onClick={() => setActiveTab("fees")}>💰 Fees</button>
                {isAdmin && <button className={activeTab === "admin" ? "active" : ""} onClick={() => setActiveTab("admin")}>🛠 Admin Panel</button>}
            </div>

            {/* Tab Content */}
            {activeTab === "overview" && renderOverview()}

            {activeTab === "my-staking" && renderMyStaking()}

            {activeTab === "actions" && renderActions()}

            {activeTab === "fees" && renderFees(

            )}

            {isAdmin && activeTab === "admin" && renderAdmin(

            )}
        </div>
    );
};

export default StakingOG;
