// src/widgets/FactoryModalLayout.jsx
import React from "react";

export default function FactoryModalLayout({
    isOpen,
    onClose,
    step,
    setStep,
    renderStep1,
    renderStep2,
}) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {step === 1 && renderStep1()}
                {step === 2 && renderStep2()}
                <button className="close-modal" onClick={() => { setStep(1); onClose(); }}>Close</button>
            </div>
        </div>
    );
}
