import React, { useState } from "react";
import "../css/ERC20.css"
import { connectWallet } from "../widgets/walletConnect";
import { getTokenSC } from "../funcs/getContract.js"
const ERC20Widget = () => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [tokenAddress, setTokenAddress] = useState("");
  const [recipient, setRecipient] = useState("");
  const [amount, setAmount] = useState("");
  const [allowance, setAllowance] = useState("");
  const [spender, setSpender] = useState("");
  const [balance, setBalance] = useState("");
  const [contract, setContract] = useState(null);



  const onConnectWallet = async () => {
    const { web3: web3Instance, account: userAccount } = await connectWallet(); // Call the connectWallet function

    setAccount(userAccount);
    setWeb3(web3Instance);
  };

  const loadContract = async () => {
    if (web3 && tokenAddress) {
      const tokenContract = await getTokenSC(web3, tokenAddress);
      setContract(tokenContract);
      alert("Contract loaded successfully!");
    } else {
      alert("Connect wallet and set token address first.");
    }
  };

  const handleTransfer = async () => {
    if (contract) {
      await contract.methods.transfer(recipient, web3.utils.toWei(amount, "ether")).send({ from: account });
      alert("Transfer successful!");
    } else {
      alert("Load the contract first.");
    }
  };

  const handleApprove = async () => {
    if (contract) {
      await contract.methods.approve(spender, web3.utils.toWei(amount, "ether")).send({ from: account });
      alert("Approval successful!");
    } else {
      alert("Load the contract first.");
    }
  };

  const checkAllowance = async () => {
    if (contract) {
      const result = await contract.methods.allowance(account, spender).call();
      setAllowance(web3.utils.fromWei(result, "ether"));
    } else {
      alert("Load the contract first.");
    }
  };

  const checkBalance = async () => {
    if (contract) {
      const result = await contract.methods.balanceOf(account).call();
      setBalance(web3.utils.fromWei(result, "ether"));
    } else {
      alert("Load the contract first.");
    }
  };

  const handleBurn = async () => {
    if (contract) {
      await contract.methods.transfer("0x0000000000000000000000000000000000000000", web3.utils.toWei(amount, "ether")).send({ from: account });
      alert("Tokens burned!");
    } else {
      alert("Load the contract first.");
    }
  };

  return (
    <div className="widget-container" style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h2>ERC20 Token Widget</h2>
      <button onClick={onConnectWallet}>Connect Wallet</button>
      <p>Connected Account: {account}</p>
      <input
        type="text"
        placeholder="Token Contract Address"
        value={tokenAddress}
        onChange={(e) => setTokenAddress(e.target.value)}
      />
      <button onClick={loadContract}>Load Contract</button>
      <br />
      <h3>Actions</h3>
      <div>
        <h4>Check Balance</h4>
        <button onClick={checkBalance}>Get Balance</button>
        <p>Balance: {balance}</p>
      </div>
      <div>
        <h4>Transfer Tokens</h4>
        <input
          type="text"
          placeholder="Recipient Address"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
        />
        <input
          type="text"
          placeholder="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <button onClick={handleTransfer}>Transfer</button>
      </div>
      <div>
        <h4>Approve Spender</h4>
        <input
          type="text"
          placeholder="Spender Address"
          value={spender}
          onChange={(e) => setSpender(e.target.value)}
        />
        <input
          type="text"
          placeholder="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <button onClick={handleApprove}>Approve</button>
      </div>
      <div>
        <h4>Check Allowance</h4>
        <input
          type="text"
          placeholder="Spender Address"
          value={spender}
          onChange={(e) => setSpender(e.target.value)}
        />
        <button onClick={checkAllowance}>Check Allowance</button>
        <p>Allowance: {allowance}</p>
      </div>
      <div>
        <h4>Burn Tokens</h4>
        <input
          type="text"
          placeholder="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <button onClick={handleBurn}>Burn</button>
      </div>
    </div>
  );
};

export default ERC20Widget;
