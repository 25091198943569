import { Link } from "react-router-dom";
import { useWallet } from "../contexts/WalletContext"; // Import wallet state
import "../css/Navbar.css";

const Navbar = () => {
    const { account, connectWallet, disconnectWallet } = useWallet();

    return (
        <nav className="navbar">
            <div className="navbar-center">
                <Link className="link" to="/stash">Stash</Link>
                <Link className="link" to="/tools">Tools</Link>
                <Link className="link" to="/tag">Tag Battle</Link>
                <Link className="link" to="/staking">Staking</Link>
                <Link className="link" to="/factory">Factory</Link>
            </div>

            <div className="navbar-right">
                {account ? (
                    <button className="logout-button" onClick={disconnectWallet}>
                        Logout
                    </button>
                ) : (
                    <button className="login-button" onClick={connectWallet}>
                        Login
                    </button>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
