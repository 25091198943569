import React from "react";
import "../css/modal.css";

const Modal = ({ title, onClose, onAction, selectedPaymentMethod, setSelectedPaymentMethod, requiresApproval, onApprove }) => (
    <div className="modal-overlay">
        <div className="modal-content">
            <h2>{title}</h2>
            <label htmlFor="payment-select">Select Payment Method:</label>
            <select
                id="payment-select"
                value={selectedPaymentMethod}
                onChange={(e) => setSelectedPaymentMethod(e.target.value.toLowerCase())}
            >

                <option value="kiddo">KIDDO</option>
                <option value="shido">SHIDO</option>

            </select>
            {requiresApproval ? (
                <button onClick={onApprove}>Approve KIDDO</button>
            ) : (
                <button onClick={onAction}>Confirm</button>
            )}
            <button onClick={onClose}>Cancel</button>
        </div>
    </div>
);

export default Modal;
