import { STAKING_ABI, TAG, TOKEN_ABI, BATCH_ABI, stashABI } from "../config.js";

export const getTokenSC = async (web3, ca = "0x2835Ad9a421C14E1C571a5Bb492B86b7E8f5873A") => {
    const kiddoContractInstance = new web3.eth.Contract(
        TOKEN_ABI,
        ca
    );
    return kiddoContractInstance;


};

export const getTagBattle = async (web3) => {
    const contractAddress = "0x5cA771A8cB1a51251174A9dfC2f06182d84914F6";
    const tagBattleContract = new web3.eth.Contract(
        TAG,
        contractAddress
    );
    const kiddoContractInstance = await getTokenSC(web3);
    return { tagBattleContract, kiddoContractInstance };


};


export const getStakingOG = async (web3) => {
    const contractAddress = "0xbf2019c320AD99F7A84664c72599D772C225eF62";
    const stakingContract = new web3.eth.Contract(
        STAKING_ABI,
        contractAddress
    );
    const kiddoContractInstance = await getTokenSC(web3);
    return { stakingContract, kiddoContractInstance };
};

export const getBatchTransfers = async (web3) => {
    const contractAddress = "0x1AD0D74967d8c91d88D88aA229a5DAf3e46538B6";
    const batch = new web3.eth.Contract(
        BATCH_ABI,
        contractAddress
    );
    return batch;
};

export const getStash = async (web3) => {
    const contractAddress = "0x5B73743d6e99E911e6C412C0BcA9a702475F0595";
    const stash = new web3.eth.Contract(
        stashABI,
        contractAddress
    );
    return stash;
};